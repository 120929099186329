.lessonCardActionButtonStandard,
.lessonCardActionButtonComplete,
.lessonCardActionButtonDisabled {
  width: 191px;
  min-width: 191px;
  max-width: 191px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 0px 0px 8px 0px; }

.lessonCardActionButtonStandard {
  background-color: #009fd1; }

.lessonCardActionButtonStandard:hover {
  background-color: #009fd1;
  background-color: #2c8fbf;
  border-radius: 0px 0px 8px 0px; }

.lessonCardActionButtonComplete {
  background-color: #ffffff; }

.lessonCardActionButtonComplete > div.LessonCardActionButtonText {
  color: #000000; }

.lessonCardActionButtonDisabled {
  background-color: transparent; }
  .lessonCardActionButtonDisabled .lessonCardActionButtonComplete > div.LessonCardActionButtonText {
    color: #c0c0c0; }

.LessonCardActionButtonText {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  color: #ffffff; }
