.reactPlayerHolder {
  background-color: #ffffff;
  position: absolute;
  left: 0.65%;
  right: 7.04%;
  top: 5.31%;
  bottom: 8.5%; }

.reactPlayer {
  width: 100%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  -webkit-transform: rotate(0deg);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  overflow: hidden;
  position: absolute;
  left: 3.65%;
  right: 7.04%;
  top: 4.31%;
  bottom: 21.94%; }

#player {
  border-radius: 10px;
  overflow: hidden;
  z-index: 1; }
