@media (min-width: 1101px) {
  #login_container {
    height: 0%;
    position: relative;
    background-color: #050a07; }
    #login_container .formWrapper {
      margin-left: 40px;
      margin-right: 40px;
      background-color: transparent;
      justify-content: center;
      text-align: center; }
  #actions {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    right: 0;
    width: 534px;
    height: 100vh;
    min-height: 100vh;
    color: #000000; }
    #actions DIV DIV.loginHeader {
      margin-top: 140px;
      margin-bottom: 20px;
      margin-left: 20px;
      font-weight: 900;
      font-size: 48px;
      line-height: 58px;
      color: #101f47;
      text-align: left;
      justify-content: left; }
  .loginFormRecovery .formDiv #reduxFormInputField1 {
    margin-bottom: 45px; } }

@media (min-width: 601px) and (max-width: 1100px) {
  #login_container {
    height: 0%;
    position: relative;
    background-color: #050a07; }
    #login_container .formWrapper {
      margin-left: 40px;
      margin-right: 40px;
      background-color: transparent;
      justify-content: center;
      text-align: center; }
  #actions {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    margin: auto;
    padding-bottom: 40px;
    width: 534px;
    color: #000000; }
    #actions DIV DIV.loginHeader {
      margin-bottom: 20px;
      margin-left: 20px;
      font-weight: 900;
      font-size: 48px;
      line-height: 58px;
      color: #101f47;
      text-align: left;
      justify-content: left;
      margin-top: 40px; }
    #actions DIV.formWrapper DIV.horizontalLinkListBlue {
      margin-bottom: 40px; }
  .loginFormRecovery .formDiv #reduxFormInputField1 {
    margin-bottom: 45px; } }

@media (max-width: 600px) {
  #login_container {
    position: relative;
    height: 100vh;
    background-color: #ffffff; }
    #login_container .formWrapper {
      margin-left: 40px;
      margin-right: 40px;
      background-color: transparent;
      justify-content: center;
      text-align: center; }
  .loginForm {
    left: 50%; }
  .loginFormUsernameWrapper,
  .loginFormPasswordWrapper,
  .loginFormRecoveryWrapper {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex; }
  .loginFormUsername,
  .loginFormPassword,
  .loginFormRecovery {
    width: 100%;
    max-width: 375px;
    text-align: left; }
  .loginFormRecovery {
    margin-bottom: 15px; }
    .loginFormRecovery DIV.formText {
      margin-bottom: 2px; }
  #actions {
    position: fixed;
    top: 209px;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #ffffff;
    margin: auto;
    padding-bottom: 40px;
    width: 100%;
    color: #000000; }
    #actions DIV.formWrapper DIV.horizontalLinkListBlue {
      margin-bottom: 40px; }
    #actions DIV DIV.loginHeader {
      margin-bottom: 10px;
      margin-left: -19px;
      font-weight: 900;
      font-size: 46px;
      line-height: 58px;
      text-align: left;
      justify-content: left;
      margin-top: -65px;
      color: #ffffff;
      white-space: nowrap;
      overflow: hidden; }
    #actions .buttonWideBlue {
      width: 100%;
      max-width: 375px; }
    #actions .buttonWideBlueWait {
      width: 100%;
      max-width: 375px; }
    #actions .formText {
      text-align: left;
      width: 100%;
      max-width: 375px;
      margin-left: 0px; } }
