@media (min-width: 768px) {
  DIV.aboutMeEdit {
    margin-top: 55px; } }

@media (max-width: 767.99px) {
  DIV.aboutMeEdit {
    margin-top: 0px; } }

DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper {
  display: grid;
  grid-gap: 10px; }
  @media (min-width: 768px) {
    DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper {
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: 4fr 4fr 1fr; } }
  @media (max-width: 767.99px) {
    DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper {
      grid-template-columns: 1fr;
      grid-template-rows: 2fr 2fr 2fr 2fr 1fr 1fr; } }
  DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemFirstName {
    margin-bottom: 105px; }
    @media (min-width: 768px) {
      DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemFirstName {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: span 2;
        margin-right: 20px; } }
    @media (max-width: 767.99px) {
      DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemFirstName {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: span 2;
        margin-right: 0px; } }
  DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemMiddleName {
    margin-bottom: 105px; }
    @media (min-width: 768px) {
      DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemMiddleName {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: span 2; } }
    @media (max-width: 767.99px) {
      DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemMiddleName {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: span 3; } }
  DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemLastName {
    margin-bottom: 0px; }
    @media (min-width: 768px) {
      DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemLastName {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: span 3;
        margin-right: 20px; } }
    @media (max-width: 767.99px) {
      DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemLastName {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: span 4;
        margin-right: 0px; } }
  DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemEmail {
    margin-bottom: 0px; }
    @media (min-width: 768px) {
      DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemEmail {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: span 3; } }
    @media (max-width: 767.99px) {
      DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemEmail {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: span 5; } }
  @media (min-width: 768px) {
    DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditButtonWrapper {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 3;
      grid-row-end: span 4; } }
  @media (max-width: 767.99px) {
    DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditButtonWrapper {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 5;
      grid-row-end: span 6; } }
  @media (min-width: 768px) {
    DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemFeedback {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: span 4;
      color: #2c8fbf; } }
  @media (max-width: 767.99px) {
    DIV.aboutMeEdit DIV.aboutMeEditFieldWrapper DIV.aboutMeEditFieldItemFeedback {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 6;
      grid-row-end: span 7;
      color: #2c8fbf;
      padding-top: 15px; } }

DIV.aboutMeEdit .sectionTitle {
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px; }

DIV.aboutMeEdit BUTTON.button-reverse-l {
  border: 1px solid #009fd1;
  white-space: nowrap;
  justify-content: center;
  text-align: center;
  padding: 0px;
  outline: 1px dashed transparent;
  font-size: 16px;
  padding-left: 32px;
  padding-right: 32px;
  width: inherit; }

DIV.aboutMeEdit BUTTON.button-default-l {
  border: none;
  white-space: nowrap;
  justify-content: center;
  text-align: center;
  outline: 1px dashed transparent;
  font-size: 16px;
  padding-left: 32px;
  padding-right: 32px;
  width: inherit; }

@media (min-width: 768px) {
  DIV.aboutMeEdit DIV.aboutMeEditButtonWrapper {
    text-align: right; } }

@media (max-width: 767.99px) {
  DIV.aboutMeEdit DIV.aboutMeEditButtonWrapper {
    text-align: center; } }
