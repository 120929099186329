.heroProfileCourseProgressWrapper {
  flex: auto;
  width: 100vw;
  min-height: 207px;
  max-height: 207px;
  background-color: transparent;
  background-image: url("../../../assets/images/components/hero/heroCourseProgress.webp");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-color: #1d2332; }
  .heroProfileCourseProgressWrapper .heroProfileCourseProgressProfile {
    border: none; }
    @media (min-width: 1920px) {
      .heroProfileCourseProgressWrapper .heroProfileCourseProgressProfile {
        margin: 55px 20px 78px 208px; } }
    @media (min-width: 1366px) and (max-width: 1919.99px) {
      .heroProfileCourseProgressWrapper .heroProfileCourseProgressProfile {
        margin: 55px 20px 78px 208px; } }
    @media (min-width: 1024px) and (max-width: 1365.99px) {
      .heroProfileCourseProgressWrapper .heroProfileCourseProgressProfile {
        margin: 55px 20px 78px 85px; } }
    @media (min-width: 768px) and (max-width: 1023.99px) {
      .heroProfileCourseProgressWrapper .heroProfileCourseProgressProfile {
        margin: 55px 20px 78px 45px; } }
    @media (min-width: 360px) and (max-width: 767.99px) {
      .heroProfileCourseProgressWrapper .heroProfileCourseProgressProfile {
        margin: 0px 20px 78px 15px; } }
    @media (max-width: 359.99px) {
      .heroProfileCourseProgressWrapper .heroProfileCourseProgressProfile {
        margin: 0px 20px 78px 15px; } }
  .heroProfileCourseProgressWrapper .heroProfileCourseProgressIntro {
    font-size: 20px;
    font-weight: 300;
    color: #a2a8b6;
    margin-bottom: 2px; }
  .heroProfileCourseProgressWrapper .heroProfileCourseProgressNameFirstName {
    color: #ffffff;
    font-weight: 800;
    text-shadow: 9px -6px 28px #ffffff55; }
  .heroProfileCourseProgressWrapper .heroProfileCourseProgressNameLastName {
    font-weight: 800;
    color: #009fd1;
    text-shadow: 9px -6px 28px #009fd155; }
