.modalWrapper {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 800; }

.modalBlocker {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1100; }

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 60px 0; }

.modalPerimeter {
  position: relative;
  z-index: 1200; }

.modalHolder {
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  max-width: 1160px; }

.modalMessagingContainer {
  padding: 30px;
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-direction: column; }

.modalHeaderText {
  font-size: 32px;
  line-height: 39px;
  color: #101f47;
  text-align: center;
  width: 100%;
  white-space: nowrap; }

.modalMessage {
  font-size: 13px;
  color: #5c6170;
  text-align: center;
  width: 100%;
  margin-bottom: 20px; }

.modalMessageContainer {
  text-align: center; }
  .modalMessageContainer BUTTON {
    font-size: 11pt;
    font-weight: 500;
    padding-left: 3px;
    padding-right: 3px; }

.modalHeaderIconClock {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #009fd1;
  position: relative;
  display: block;
  zoom: 300%; }
  .modalHeaderIconClock::before {
    content: '';
    height: 9px;
    width: 1px;
    background-color: white;
    display: block;
    position: absolute;
    left: 9px;
    top: 3px;
    opacity: 0.6; }
  .modalHeaderIconClock::after {
    content: '';
    height: 6px;
    width: 1px;
    background-color: white;
    display: block;
    position: absolute;
    top: 7px;
    left: 11px;
    transform: rotate(45deg);
    opacity: 0.6; }

.modalHeaderIconCheckmark {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #009fd1;
  border-radius: 50%;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  zoom: 300%; }

.modalHeaderIconCheckmark {
  content: '';
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  left: 9px;
  top: 4px; }

.modalHeaderIconCheckmark:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #fff;
  left: 6px;
  top: 10px; }

.modalHeaderIconXFail {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #e4333e;
  border-radius: 50%;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  zoom: 300%; }

.modalHeaderIconXFail {
  content: '';
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  left: 8px;
  top: 5px; }

.modalHeaderIconFail:after {
  content: '';
  position: absolute;
  width: 9px;
  height: 3px;
  background-color: #fff;
  left: 5px;
  top: 8px; }
