.headerCartButton {
  border: none;
  background-color: transparent;
  color: transparent;
  cursor: pointer;
  color: transparent;
  margin-top: -8px;
  margin-left: -9px; }
  .headerCartButton SPAN {
    height: 24px;
    width: 24px;
    position: fixed; }
    .headerCartButton SPAN SPAN {
      margin-top: 9px;
      margin-right: 9px;
      background-color: #e4333e !important;
      font-weight: 800 !important;
      font-size: 14pt !important; }

.headerCartButtonLink {
  border: none;
  background-color: transparent; }

.headerCartButtonSpan {
  border: none;
  background-color: transparent;
  color: #ffffff;
  font-family: 'Lexand Deca', 'Khula', sans-serif;
  font-weight: 700; }

.headerCartButtonSpanEmptyCart {
  border: none;
  background-color: transparent;
  color: transparent;
  font-family: 'Lexand Deca', 'Khula', sans-serif;
  font-weight: 700; }

/* reactjs-popup */
DIV.popup-content {
  background-color: #ffffff;
  margin: auto;
  padding: 5px 10px 10px 10px;
  border-radius: 16px;
  border: 1px solid transparent; }

.MuiBadge-root {
  background-color: transparent; }

.popup-arrow {
  color: transparent; }

[role='tooltip'].popup-content {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px; }

.popup-overlay {
  background: rgba(0, 0, 0, 0.5); }

[data-popup='tooltip'].popup-overlay {
  background: transparent; }
