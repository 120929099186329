DIV.profilePhoto > SPAN > SPAN.MuiBadge-badge {
  margin-top: 0px;
  margin-right: 0px;
  background-color: #e4333e !important;
  font-weight: 800 !important;
  font-size: 14pt !important; }

.profileImageSmall {
  width: inherit;
  height: inherit; }
