DIV.paymentPageWrapper {
  border: none;
  background-color: transparent;
  width: 100%;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif; }
  @media (min-width: 1001px) {
    DIV.paymentPageWrapper DIV.paymentPageActions {
      background-color: transparent;
      float: left; } }
  @media (max-width: 1000px) {
    DIV.paymentPageWrapper DIV.paymentPageActions {
      background-color: transparent;
      float: left;
      width: 90%; } }
  DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro {
    border: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    min-width: 347px; }
    DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPageIntroStep {
      border: none; }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPageIntroStep .checkoutFormPersonalIntro {
        display: flex;
        flex-direction: row;
        margin-right: 10px; }
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPageIntroStep .checkoutFormPersonalIntro DIV.MuiAvatar-root {
          font-size: 17px;
          margin-right: 10px; }
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPageIntroStep .checkoutFormPersonalIntro DIV.checkoutFormPersonalIntroText {
          margin-top: 3px; }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPageIntroStep .paymentPageIntroStepNumber {
        background: #858994;
        border-radius: 50%;
        color: #ffffff;
        height: 1.4em;
        position: relative;
        width: 1.4em;
        float: left;
        margin-right: 15px; }
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPageIntroStep .paymentPageIntroStepNumber P {
          hyphens: auto;
          text-align: center;
          color: #ffffff;
          float: left;
          padding-left: 6px;
          font-weight: 700;
          padding-top: 2px;
          padding-left: 6px; }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPageIntroStep .paymentPageIntroText {
        float: left;
        margin-top: 3px;
        font-weight: 700; }
    DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPagePersonalInfo {
      background-color: #ffffff;
      margin: 10px 0px 10px 0px;
      border-radius: 8px;
      display: flex;
      flex-direction: row; }
      @media (min-width: 1001px) {
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPagePersonalInfo {
          width: 499px;
          padding: 8px; } }
      @media (max-width: 1000px) {
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPagePersonalInfo {
          width: 100%;
          padding: 8px 0px 8px 0px; } }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPagePersonalInfo DIV.paymentPagePersonalInfoDetails {
        font-size: 13px;
        line-height: 189.2%;
        color: #1d2332;
        width: 85%; }
        @media (min-width: 1001px) {
          DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPagePersonalInfo DIV.paymentPagePersonalInfoDetails {
            padding: 10px; } }
        @media (max-width: 1000px) {
          DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPagePersonalInfo DIV.paymentPagePersonalInfoDetails {
            padding: 0px 33px 0px 33px; } }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPagePersonalInfo DIV.paymentPagePersonalInfoChange {
        font-size: 13px;
        width: 15%;
        text-align: right;
        padding: 10px; }
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPagePersonalInfo DIV.paymentPagePersonalInfoChange A,
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageIntro DIV.paymentPagePersonalInfo DIV.paymentPagePersonalInfoChange A:visited {
          color: #009fd1;
          text-decoration: none;
          cursor: pointer;
          font-weight: 600; }
  DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column; }
    @media (min-width: 1001px) {
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction {
        width: 489px; } }
    @media (max-width: 1000px) {
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction {
        width: 100%; } }
    DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPagePaymentStep {
      border: none; }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPagePaymentStep .checkoutFormPersonalIntro {
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        margin-bottom: 10px; }
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPagePaymentStep .checkoutFormPersonalIntro DIV.MuiAvatar-root {
          font-size: 17px;
          margin-right: 10px; }
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPagePaymentStep .checkoutFormPersonalIntro DIV.checkoutFormPersonalIntroText {
          margin-top: 3px; }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPagePaymentStep DIV.paymentPagePaymentStepNumber {
        background: #009fd1;
        border-radius: 50%;
        color: #ffffff;
        height: 1.4em;
        position: relative;
        width: 1.4em;
        float: left;
        margin-right: 15px; }
        DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPagePaymentStep DIV.paymentPagePaymentStepNumber P {
          hyphens: auto;
          text-align: center;
          color: #ffffff;
          float: left;
          padding-left: 6px;
          font-weight: 700;
          padding-top: 2px;
          padding-left: 6px; }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPagePaymentStep DIV.paymentPagePaymentText {
        float: left;
        margin-top: 3px;
        font-weight: 700; }
    DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPageCardSection {
      border: none;
      background-color: transparent;
      width: 489px; }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPageCardSection DIV.paymentPagePaymentType {
        display: flex;
        flex-direction: row; }
      DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPageCardSection DIV {
        margin: 10px 30px 10px 0px; }
    DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageInteraction DIV.paymentPageSubmitButton {
      border: none;
      background-color: rebeccapurple;
      width: 489px; }
  DIV.paymentPageWrapper DIV.paymentPageActions DIV.paymentPageMessaging {
    border: none;
    background-color: transparent; }
  DIV.paymentPageWrapper DIV.paymentPageSummary {
    background-color: #ffffff;
    width: 415px;
    min-width: 415px;
    max-width: 415px;
    height: 100vh;
    margin-top: -122px;
    float: right;
    margin-right: 8px; }

BUTTON.paymentPageSubmitButtonEnabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 19px 32px 16px 32px;
  position: static;
  width: 100%;
  height: 48px;
  background: #009fd1;
  border-radius: 6px;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 24px 0px;
  color: #ffffff;
  font-weight: 500;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif; }

BUTTON.paymentPageSubmitButtonDisabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  position: static;
  width: 100%;
  height: 48px;
  background: #99d9ed;
  border-radius: 6px;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 24px 0px;
  color: #ffffff;
  font-weight: 500;
  border: none; }

@media (max-width: 1000px) {
  DIV.paymentPagePaymentStep + DIV.StripeElement {
    padding: 8px 0px 8px 0px;
    min-width: 347px !important; } }
