.resourceWrapper {
  width: 100%;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto 191px;
  margin-bottom: 24px;
  border-radius: 8px; }
  .resourceWrapper .resourceCardColumnOne {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    padding: 24px; }
  .resourceWrapper .resourceInfo {
    background-color: transparent;
    flex-grow: 1; }
    .resourceWrapper .resourceInfo .resourceName {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #101f47;
      margin-bottom: 7px; }
    .resourceWrapper .resourceInfo .resourceDescription {
      color: #858994; }
      .resourceWrapper .resourceInfo .resourceDescription .resourceDescriptionShowMoreLink,
      .resourceWrapper .resourceInfo .resourceDescription .resourceDescriptionShowMoreLink:visited {
        font-weight: 600;
        color: #009fd1; }
  .resourceWrapper .resourceCardColumnTwo {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    background-color: #f2f4f9; }
    .resourceWrapper .resourceCardColumnTwo .resourceImage {
      border: 0px;
      margin: 0px;
      width: 173px;
      min-width: 173px;
      max-width: 173px;
      height: 146px;
      min-height: 146px;
      max-height: 146px; }
    .resourceWrapper .resourceCardColumnTwo .resourceCardImageWrapper {
      border: 0px;
      margin: 0px;
      width: 191px;
      min-width: 191px;
      max-width: 191px;
      height: 162px;
      min-height: 162px;
      max-height: 162px;
      border-radius: 0px 8px 0px 0px; }
      .resourceWrapper .resourceCardColumnTwo .resourceCardImageWrapper .resourceCardImage {
        object-fit: cover;
        width: 191px;
        min-width: 191px;
        max-width: 191px;
        height: 162px;
        min-height: 162px;
        max-height: 162px;
        border-radius: 0px 8px 0px 0px; }
    .resourceWrapper .resourceCardColumnTwo .resourceCardActionButton {
      border: none;
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      width: 173px;
      min-width: 173px;
      max-width: 173px; }

.resourceCardActionButtonStandard,
.resourceCardActionButtonComplete,
.resourceCardActionButtonDisabled {
  width: 191px;
  min-width: 191px;
  max-width: 191px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 0px 0px 8px 0px; }

.resourceCardActionButtonStandard {
  background-color: #009fd1; }

.resourceCardActionButtonStandard:hover {
  background-color: #009fd1;
  background-color: #2c8fbf;
  border-radius: 0px 0px 8px 0px; }

.resourceCardActionButtonComplete {
  background-color: #ffffff; }

.resourceCardActionButtonComplete > div.LessonCardActionButtonText {
  color: #000000; }

.resourceCardActionButtonDisabled {
  background-color: transparent; }
  .resourceCardActionButtonDisabled .lessonCardActionButtonComplete > div.LessonCardActionButtonText {
    color: #c0c0c0; }

.resourceCardActionButtonText {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  color: #ffffff; }
