@charset "UTF-8";
.instructorLedPageContainer {
  background-color: transparent; }

.sessionHeaderDownloadCalendarLinkWrapper {
  background-color: dodgerblue; }
  .sessionHeaderDownloadCalendarLinkWrapper BUTTON.sessionHeaderDownloadCalendarLink {
    position: absolute;
    top: 370px;
    left: 50px;
    background: #009fd1;
    width: 326px;
    align-items: left;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 56px;
    justify-content: left;
    line-height: 17px;
    outline: none;
    padding-top: 6px;
    text-align: left;
    text-decoration: none;
    transition: all 0.15s ease-in-out; }
    .sessionHeaderDownloadCalendarLinkWrapper BUTTON.sessionHeaderDownloadCalendarLink DIV.sessionHeaderDownloadCalendarLinkContent {
      display: flex;
      flex-direction: row;
      justify-content: left; }
      .sessionHeaderDownloadCalendarLinkWrapper BUTTON.sessionHeaderDownloadCalendarLink DIV.sessionHeaderDownloadCalendarLinkContent DIV.sessionHeaderDownloadCalendarLinkButtonImage {
        margin-bottom: 0px;
        margin-left: 10px; }
      .sessionHeaderDownloadCalendarLinkWrapper BUTTON.sessionHeaderDownloadCalendarLink DIV.sessionHeaderDownloadCalendarLinkContent DIV.sessionHeaderDownloadCalendarLinkButtonText {
        margin-top: 5px;
        margin-left: 17px; }

.instructorLedTitle {
  color: #ffffff;
  font-size: 38px;
  font-weight: 800;
  margin-top: -135px;
  margin-bottom: 100px;
  line-height: 38px; }

DIV.instructorLedImagePrimaryWrapper > IMG.instructorLedImagePrimary {
  position: absolute;
  top: 116px;
  left: 50px;
  width: 326px;
  height: 225px;
  border-radius: 6px;
  object-fit: cover; }

.courseIntroWrapper {
  background-color: #ffffff;
  box-shadow: 0px 16px 40px rgba(90, 101, 118, 0.04);
  border-radius: 8px;
  margin-top: 10px; }

.instructorLedIntroWrapper {
  background-color: #ffffff;
  box-shadow: 0px 16px 40px rgb 90 101 118/4%;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px; }
  .instructorLedIntroWrapper .instructorLedDescription {
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
    color: #858994;
    padding: 15px; }
