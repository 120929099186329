.verticalActionButtonContainer {
  vertical-align: bottom;
  text-align: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  cursor: pointer;
  background-color: #ffffff;
  color: #009fd1;
  height: 100%;
  border-radius: 0px 0px 8px 8px; }

.verticalActionButtonContainerInProgress {
  vertical-align: bottom;
  text-align: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  cursor: pointer;
  background-color: #009fd1;
  color: #ffffff;
  height: 100%;
  border-radius: 0px 0px 8px 8px; }

.verticalActionButtonContainerInProgress:hover {
  background-color: #2d90ba; }

.verticalActionButtonContainerComplete {
  vertical-align: bottom;
  text-align: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  cursor: pointer;
  background-color: #ffffff;
  color: #009fd1;
  height: 100%;
  border-radius: 0px 0px 8px 8px; }

.verticalActionButtonContainerInCart {
  vertical-align: bottom;
  text-align: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  cursor: pointer;
  background-color: #ffffff;
  color: #009fd1;
  height: 100%;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #009fd1; }

div.verticalActionButtonContainer > button.verticalActionButton > div.generic > div {
  font-family: "Lexand Deca", "Google Sans", "Khula", "Open Sans", "Lato", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 7px; }

.verticalActionButton {
  background-color: transparent;
  border: none;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #009fd1;
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
  width: 232px; }

.div.verticalActionButton > div > div {
  font-weight: 700; }

.verticalActionButtonText {
  font-family: "Lexand Deca", "Google Sans", "Khula", "Open Sans", "Lato", Helvetica, Verdana, Arial, sans-serif; }

.verticalActionButtonInProgress {
  background-color: transparent;
  border: none;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
  width: 232px; }

.div.verticalActionButtonInProgress > div > div {
  font-weight: 700; }

.verticalActionButtonTextInProgress {
  font-family: "Lexand Deca", "Google Sans", "Khula", "Open Sans", "Lato", Helvetica, Verdana, Arial, sans-serif;
  color: #ffffff; }

.verticalActionButtonComplete {
  background-color: transparent;
  border: none;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #009fd1;
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
  width: 232px; }

.div.verticalActionButtonComplete > div > div {
  font-weight: 700; }

.verticalActionButtonTextComplete {
  font-family: "Lexand Deca", "Google Sans", "Khula", "Open Sans", "Lato", Helvetica, Verdana, Arial, sans-serif;
  color: #ffffff; }

.verticalActionButtonInCart {
  background-color: transparent;
  border: none;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #009fd1;
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
  width: 232px; }

.horizontalActionButtonContainer {
  horizontal-align: bottom;
  text-align: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  cursor: pointer;
  background-color: #ffffff;
  color: #009fd1;
  height: 100%;
  border-radius: 0px 0px 8px 0px; }

.horizontalActionButtonContainerInProgress {
  horizontal-align: bottom;
  text-align: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  cursor: pointer;
  background-color: #009fd1;
  color: #ffffff;
  height: 100%;
  border-radius: 0px 0px 8px 0px; }

.horizontalActionButtonContainerComplete {
  horizontal-align: bottom;
  text-align: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  cursor: pointer;
  background-color: #ffffff;
  color: #009fd1;
  height: 100%;
  border-radius: 0px 0px 8px 0px; }

.horizontalActionButtonContainerInCart {
  horizontal-align: bottom;
  text-align: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  cursor: pointer;
  background-color: #ffffff;
  color: #009fd1;
  height: 100%;
  border-radius: 0px 0px 8px 0px;
  border: 1px solid #009fd1; }

div.horizontalActionButtonContainer > button.horizontalActionButton > div.generic > div {
  font-family: "Lexand Deca", "Google Sans", "Khula", "Open Sans", "Lato", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 7px; }

.horizontalActionButton {
  background-color: transparent;
  border: none;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #009fd1;
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
  padding-top: 4px;
  width: 191px; }

.div.horizontalActionButton > div > div {
  font-weight: 700; }

.horizontalActionButtonText {
  font-family: "Lexand Deca", "Google Sans", "Khula", "Open Sans", "Lato", Helvetica, Verdana, Arial, sans-serif; }

.horizontalActionButtonInProgress {
  background-color: transparent;
  border: none;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
  padding-top: 4px;
  width: 191px; }

.horizontalActionButtonInProgress:hover {
  background-color: #2c8fbf;
  border-radius: 0px 0px 8px 0px; }

.div.horizontalActionButtonInProgress > div > div {
  font-weight: 700; }

.horizontalActionButtonTextInProgress {
  font-family: "Lexand Deca", "Google Sans", "Khula", "Open Sans", "Lato", Helvetica, Verdana, Arial, sans-serif;
  color: #ffffff; }

.horizontalActionButtonComplete {
  background-color: transparent;
  border: none;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #009fd1;
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
  padding-top: 4px;
  width: 191px; }

.div.horizontalActionButtonComplete > div > div {
  font-weight: 700; }

.horizontalActionButtonTextComplete {
  font-family: "Lexand Deca", "Google Sans", "Khula", "Open Sans", "Lato", Helvetica, Verdana, Arial, sans-serif;
  color: #ffffff; }

.horizontalActionButtonInCart {
  background-color: transparent;
  border: none;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #009fd1;
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
  width: 191px; }

.genericText {
  font-weight: 400; }
