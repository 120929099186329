.searchHero {
  flex: auto;
  width: 100%;
  min-height: 144px;
  max-height: 144px;
  background-color: transparent;
  background-image: url("../../assets/images/components/hero/heroSearch.webp");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-color: #1d2332; }
  .searchHero .heroCatalogContent {
    border: none; }
    @media (min-width: 1920px) {
      .searchHero .heroCatalogContent {
        margin: 35px 20px 78px 195px; } }
    @media (min-width: 1366px) and (max-width: 1919.99px) {
      .searchHero .heroCatalogContent {
        margin: 35px 20px 78px 195px; } }
    @media (min-width: 1024px) and (max-width: 1365.99px) {
      .searchHero .heroCatalogContent {
        margin: 35px 20px 78px 62px; } }
    @media (min-width: 768px) and (max-width: 1023.99px) {
      .searchHero .heroCatalogContent {
        margin: 35px 20px 78px 62px; } }
    @media (min-width: 360px) and (max-width: 767.99px) {
      .searchHero .heroCatalogContent {
        margin: 0px 20px 78px 35px; } }
    @media (max-width: 359.99px) {
      .searchHero .heroCatalogContent {
        margin: 0px 20px 78px 35px; } }
  .searchHero .heroCatalogContentMessage {
    color: #ffffff;
    width: auto; }
    @media (min-width: 1920px) {
      .searchHero .heroCatalogContentMessage {
        font-size: 48px;
        font-weight: 800; } }
    @media (min-width: 1366px) and (max-width: 1919.99px) {
      .searchHero .heroCatalogContentMessage {
        font-size: 48px;
        font-weight: 800; } }
    @media (min-width: 1024px) and (max-width: 1365.99px) {
      .searchHero .heroCatalogContentMessage {
        font-size: 48px;
        font-weight: 800; } }
    @media (min-width: 768px) and (max-width: 1023.99px) {
      .searchHero .heroCatalogContentMessage {
        font-size: 48px;
        font-weight: 800; } }
    @media (min-width: 360px) and (max-width: 767.99px) {
      .searchHero .heroCatalogContentMessage {
        font-size: 48px;
        font-weight: 800; } }
    @media (max-width: 359.99px) {
      .searchHero .heroCatalogContentMessage {
        font-size: 30px;
        font-weight: 500; } }
