DIV.objectLessonWrapper {
  background: url("../../assets/images/global/branding/splashNoLogo.webp") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 48px) !important; }
  DIV.objectLessonWrapper DIV.objectLessonContainer {
    border: none;
    background-color: #ffffff;
    border-radius: 10px;
    height: 425px;
    max-width: 450px;
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    text-align: center;
    justify-content: center; }
    DIV.objectLessonWrapper DIV.objectLessonContainer DIV.objectLessonInstructions {
      border: none;
      margin-top: 40px;
      margin-bottom: 20px; }
    DIV.objectLessonWrapper DIV.objectLessonContainer DIV.objectLessonDownloadLink {
      border: none;
      margin-bottom: 70px;
      cursor: pointer; }
      DIV.objectLessonWrapper DIV.objectLessonContainer DIV.objectLessonDownloadLink A.objectLessonCloseIcon {
        margin: 0px auto;
        background-color: #ffffff;
        border: 1px solid #009fd1;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        padding: 15px 40px 15px 40px;
        white-space: nowrap;
        width: 250px; }
    DIV.objectLessonWrapper DIV.objectLessonContainer DIV.objectLessonCloseWindowButton {
      text-align: center;
      justify-content: center;
      border: none;
      margin-bottom: 20px;
      cursor: pointer; }
    DIV.objectLessonWrapper DIV.objectLessonContainer DIV.objectLessonDownloadButtonWrapper {
      border: none;
      justify-content: center;
      text-align: center; }
      DIV.objectLessonWrapper DIV.objectLessonContainer DIV.objectLessonDownloadButtonWrapper BUTTON.objectLessonCloseButton {
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        white-space: nowrap;
        width: 195px;
        margin: 0px auto; }
