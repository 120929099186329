DIV.notificationsPageContentContainer DIV.headerPageTitle {
  color: #ffffff;
  font-size: 48px;
  font-weight: 800;
  line-height: 38px; }
  @media (min-width: 1920px) {
    DIV.notificationsPageContentContainer DIV.headerPageTitle {
      margin: -135px 20px 100px 0px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.notificationsPageContentContainer DIV.headerPageTitle {
      margin: -135px 20px 100px 0px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV.notificationsPageContentContainer DIV.headerPageTitle {
      margin: -135px 20px 100px 0px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV.notificationsPageContentContainer DIV.headerPageTitle {
      margin: -135px 20px 100px 0px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.notificationsPageContentContainer DIV.headerPageTitle {
      margin: -135px 20px 100px 0px; } }
  @media (max-width: 359.99px) {
    DIV.notificationsPageContentContainer DIV.headerPageTitle {
      margin: -135px 20px 100px 0px; } }
