.spinnerContainer {
  position: relative;
  display: inline-table;
  height: 6px;
  width: 6px; }

.spinnerLoader {
  animation: buttonAnimation 1.3s linear infinite;
  border-radius: 50%;
  color: #fff;
  height: 6px;
  width: 6px; }

.spinnerLoaderInverted {
  animation: buttonAnimation 1.3s linear infinite;
  border-radius: 50%;
  color: #009fd1;
  height: 6px;
  width: 6px; }

@keyframes buttonAnimation {
  0%,
  to {
    box-shadow: 0 -9px 0 0.2px, 6px -6px 0 0, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 0; }
  12.5% {
    box-shadow: 0 -9px 0 0, 6px -6px 0 0.2px, 9px 0 0 0, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 -3px; }
  25% {
    box-shadow: 0 -9px 0 -0.5px, 6px -6px 0 0, 9px 0 0 0.2px, 6px 6px 0 0, 0 9px 0 -3px, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 -3px; }
  37.5% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 0, 6px 6px 0 0.2px, 0 9px 0 0, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 -3px; }
  50% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 0, 0 9px 0 0.2px, -6px 6px 0 0, -9px 0 0 -3px, -6px -6px 0 -3px; }
  62.5% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 0, -6px 6px 0 0.2px, -9px 0 0 0, -6px -6px 0 -3px; }
  75% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 0, -9px 0 0 0.2px, -6px -6px 0 0; }
  87.5% {
    box-shadow: 0 -9px 0 0, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 0, -9px 0 0 0, -6px -6px 0 0.2px; } }
