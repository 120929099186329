.nextLessonOverlayContainer {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0px;
  z-index: 20000; }

.nextLessonOverlayWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center; }

.nextLessonOverlayHolder {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding: 40px;
  width: 50%;
  max-width: 500px;
  min-height: 500px;
  border-radius: 8px;
  margin: auto; }

.nextLessonOverlayPackage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  text-align: center; }

.nextLessonOverlayIconWrapper {
  margin: auto; }

.nextLessonOverlayIcon {
  height: 64px;
  width: 64px;
  margin-bottom: 10px; }

.nextLessonOverlayHeadline {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  padding-bottom: 12px;
  width: 100%; }

.nextLessonOverlayCircularProgressWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: auto; }

.nextLessonOverlayBodyContentWrapper {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow-y: auto;
  width: 100%;
  margin-top: 25px; }

.nextLessonOverlayPercentComplete {
  font-size: 13px;
  line-height: 189.2%;
  padding-bottom: 10px;
  margin-bottom: 30px; }

.nextLessonOverlayProgressWrapper {
  background: #b2b2b2;
  height: 5px;
  cursor: pointer; }

.nextLessonOverlayContent {
  display: flex;
  flex-direction: column;
  overflow-y: auto; }

.nextLessonOverlayNameHeader {
  padding-bottom: 10px;
  padding-top: 5px; }

.nextLessonOverlayName {
  font-weight: 700;
  margin-bottom: 30px;
  overflow-y: auto; }

.nextLessonOverlayButtons {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: center;
  text-align: center; }

.nextLessonOverlayPlayButtonWrapper {
  position: relative; }

.nextLessonOverlayPlayButton:focus,
.nextLessonOverlayPlayButton:hover {
  visibility: visible; }

.nextLessonOverlayPlayButton {
  background-color: #00adee;
  border-radius: 5px;
  color: #fff;
  padding: 0;
  width: 100%;
  min-height: 30px; }

.nextLessonOverlayPlayButtonText {
  display: block;
  flex-grow: 1;
  font-size: 16px !important;
  text-align: center;
  white-space: nowrap;
  font-size: 12px; }

.nextLessonOverlayCloseButtonWrapper {
  border: none; }

.nextLessonOverlayCloseButton:focus,
.nextLessonOverlayCloseButton:hover {
  visibility: visible; }

.nextLessonOverlayCloseButton {
  background-color: #00adee;
  border-radius: 5px;
  color: #fff;
  padding: 0;
  width: 100%;
  min-height: 30px; }

.nextLessonOverlayCloseButtonText {
  display: block;
  flex-grow: 1;
  font-size: 16px !important;
  text-align: center;
  white-space: nowrap;
  font-size: 12px; }
