DIV.lessonPlayerWrapper {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-color: #ffffff; }
  DIV.lessonPlayerWrapper DIV.lessonPlayerNavbar {
    background-color: #21293e;
    height: 48px; }
    DIV.lessonPlayerWrapper DIV.lessonPlayerNavbar DIV.lessonPlayerLessonName {
      color: #ffffff;
      font-weight: 900;
      font-size: 16px;
      line-height: 19px;
      background-color: transparent;
      font-family: 'Lexand Deca', 'Google Sans', 'Khula', 'Open Sans', 'Lato', Helvetica, Verdana, Arial, sans-serif;
      margin-right: 50px;
      vertical-align: middle;
      justify-content: left;
      text-align: left;
      padding: 14px 10px 10px 10px; }
    DIV.lessonPlayerWrapper DIV.lessonPlayerNavbar DIV.lessonPlayerCloseButtonHolder {
      text-align: right;
      width: 100%;
      height: 30px; }
      DIV.lessonPlayerWrapper DIV.lessonPlayerNavbar DIV.lessonPlayerCloseButtonHolder BUTTON.lessonPlayerCloseButton {
        text-align: right;
        margin: 0px 10px 20px 0px;
        background: transparent;
        border: none; }
        DIV.lessonPlayerWrapper DIV.lessonPlayerNavbar DIV.lessonPlayerCloseButtonHolder BUTTON.lessonPlayerCloseButton DIV.lessonPlayerCloseButtonIcon {
          position: absolute;
          top: 9px;
          right: 9px;
          height: 24px;
          width: 24px;
          background: url("../../assets/images/icons/x.png") no-repeat;
          cursor: pointer;
          filter: invert(100%); }
        DIV.lessonPlayerWrapper DIV.lessonPlayerNavbar DIV.lessonPlayerCloseButtonHolder BUTTON.lessonPlayerCloseButton SPAN.videoPlayerCloseButtonText {
          border: none; }
