.courseTags {
  display: flex;
  align-items: center; }

div.courseTags {
  padding-top: 10px; }

div.courseTags > div > button {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #009fd1;
  background: rgba(55, 203, 236, 0.2);
  border-radius: 8px;
  align-items: center;
  margin: 0px 0px 0px 15px;
  padding: 12px 24px;
  border: none; }

.courseIntroWrapper {
  background-color: #ffffff;
  box-shadow: 0px 16px 40px rgba(90, 101, 118, 0.04);
  border-radius: 8px;
  margin-top: 10px; }

.courseDescription {
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  color: #858994;
  padding: 15px; }
