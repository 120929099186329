.EntitiesFilterWrapper {
  background-color: none; }
  @media (max-width: 1365.99px) {
    .EntitiesFilterWrapper {
      visibility: hidden; } }

ul.EntitiesFilterCheckBoxList {
  list-style-type: none;
  padding: 0;
  border: none; }

ul.EntitiesFilterCheckBoxList > li {
  padding: 4px 0px;
  border-bottom: none;
  margin: 5px 0px;
  font-size: 16px;
  line-height: 24px; }

ul.EntitiesFilterCheckBoxList > DIV.entityFilterCheckBoxListItem > ul.generic > li {
  padding: 4px 0px;
  border-bottom: none;
  margin: 5px -20px;
  font-size: 16px;
  line-height: 24px; }

ul.EntitiesFilterCheckBoxList > li:last-child,
ul.EntitiesFilterCheckBoxList > DIV.entityFilterCheckBoxListItem > ul.generic > li {
  border-bottom: none; }

.EntitiesFilterHeaderText {
  font-weight: 700;
  margin-left: 0px;
  font-size: 18px;
  line-height: 24px;
  color: #1d2332; }
