DIV.pageActionAreaLinks {
  background-color: transparent;
  position: absolute;
  display: flex;
  flex-direction: column; }
  @media (min-width: 1920px) {
    DIV.pageActionAreaLinks {
      top: 370px;
      left: 50px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.pageActionAreaLinks {
      top: 370px;
      left: 50px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV.pageActionAreaLinks {
      top: 305px;
      left: 50px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV.pageActionAreaLinks {
      top: 305px;
      left: 50px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.pageActionAreaLinks {
      top: 402px;
      left: calc((100vw - 326px) / 2); } }
  @media (max-width: 359.99px) {
    DIV.pageActionAreaLinks {
      top: 402px;
      left: calc((100vw - 326px) / 2); } }
  DIV.pageActionAreaLinks DIV.pageActionAreaLinksSpinner {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-bottom: 8px; }
  DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton {
    background: #009fd1;
    color: #ffffff;
    border: 1px solid #009fd1;
    cursor: pointer; }
  DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction {
    background: transparent;
    color: #009fd1;
    border: 1px solid #009fd1;
    cursor: not-allowed; }
  DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse {
    background: #ffffff;
    color: #009fd1;
    border: 1px solid #009fd1;
    cursor: pointer; }
  DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton,
  DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse,
  DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction {
    align-items: left;
    border-radius: 4px;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 56px;
    justify-content: left;
    line-height: 17px;
    outline: none;
    padding-top: 14px;
    text-align: left;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    margin-bottom: 10px; }
    @media (min-width: 1920px) {
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction {
        width: 326px; } }
    @media (min-width: 1366px) and (max-width: 1919.99px) {
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction {
        width: 326px; } }
    @media (min-width: 1024px) and (max-width: 1365.99px) {
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction {
        width: 230px; } }
    @media (min-width: 768px) and (max-width: 1023.99px) {
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction {
        width: 230px; } }
    @media (min-width: 360px) and (max-width: 767.99px) {
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction {
        width: 326px; } }
    @media (max-width: 359.99px) {
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction {
        width: 326px; } }
    DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton DIV.pageActionAreaLinkButtonContent,
    DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse DIV.pageActionAreaLinkButtonContent,
    DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction DIV.pageActionAreaLinkButtonContent {
      display: flex;
      flex-direction: row;
      justify-content: left; }
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonImage,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonImage,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonImage {
        margin: -10px 17px 0px 10px; }
        DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonImage IMG.pageActionAreaLinkButtonImageCss,
        DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonImage IMG.pageActionAreaLinkButtonImageCss,
        DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonImage IMG.pageActionAreaLinkButtonImageCss {
          height: 19px;
          width: 19px;
          margin-top: 2px; }
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonText,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonText,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonText {
        margin-top: 5px;
        margin-left: 7px;
        font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 17px;
        margin-top: -4px; }
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButton DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonTextPrice,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonReverse DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonTextPrice,
      DIV.pageActionAreaLinks BUTTON.pageActionAreaLinkButtonNoAction DIV.pageActionAreaLinkButtonContent DIV.pageActionAreaLinkButtonTextPrice {
        margin-top: 5px;
        margin-left: 7px;
        font-family: "Lexand Deca", "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 17px;
        margin-top: -4px; }
