.carousel__title {
  padding-bottom: 10px; }

DIV.additionalContentLink > A {
  color: #009fd1;
  width: 100%;
  text-align: left;
  margin-left: 20px;
  font-size: 12pt;
  text-decoration: none; }
  DIV.additionalContentLink > A :visited {
    color: #009fd1; }
