.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  font-family: 'Open Sans, Helvetica Neue, Helvetica, sans-serif';
  background-color: #fff;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement::placeholder {
  color: #aab7c4; }

.StripeElement::-webkit-input-placeholder {
  color: #aab7c4; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #e44555;
  color: #e44555; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }
