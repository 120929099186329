.courseWrapper {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: auto 191px;
  grid-template-rows: auto 54px;
  margin-bottom: 20px;
  border-radius: 8px; }

.courseInfo {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding: 15px; }

.courseImage {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  border: 0px;
  margin: 0px;
  width: 191px;
  min-width: 191px;
  max-width: 191px;
  height: 162px;
  min-height: 162px;
  max-height: 162px;
  border-radius: 0px 8px 0px 0px; }

.courseName {
  border: none; }

.courseDescription {
  border: none; }

.courseCardActionButton {
  border: none;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  width: 191px;
  min-width: 191px;
  max-width: 191px; }

.imageCourse {
  object-fit: cover;
  border-radius: 0px 8px 0px 0px; }
  @media (min-width: 1920px) {
    .imageCourse {
      width: 326px;
      min-width: 326px;
      max-width: 326px;
      height: 225px;
      min-height: 225px;
      max-height: 225px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    .imageCourse {
      width: 326px;
      min-width: 326px;
      max-width: 326px;
      height: 225px;
      min-height: 225px;
      max-height: 225px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    .imageCourse {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
      height: 174px;
      min-height: 174px;
      max-height: 174px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    .imageCourse {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
      height: 174px;
      min-height: 174px;
      max-height: 174px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    .imageCourse {
      width: 326px;
      min-width: 326px;
      max-width: 326px;
      height: 326px;
      min-height: 326px;
      max-height: 326px; } }
  @media (max-width: 359.99px) {
    .imageCourse {
      width: 326px;
      min-width: 326px;
      max-width: 326px;
      height: 326px;
      min-height: 326px;
      max-height: 326px; } }

.curriculumGroupName {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #8c909b;
  margin-bottom: 15px; }
