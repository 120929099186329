.card-module__card_bottom___2pcXZ {
  cursor: unset; }

.card-module__name___1kcxc:hover {
  cursor: pointer;
  text-decoration: underline; }

.cardContainer {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-left: 20px; }

.globalResourceCard {
  border-radius: 8px;
  display: inline-block;
  box-shadow: 45px 45px 80px -5px rgba(0, 0, 0, 0.25);
  transition: box-shadow ease-in-out 0.6s;
  -ms-display: -ms-grid;
  display: grid;
  background-color: transparent;
  border: none;
  min-width: 232px;
  max-width: 232px;
  margin-right: 30px;
  margin-bottom: 40px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px; }

.globalResourceCard:hover {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  box-shadow: 45px 45px 80px -5px rgba(0, 0, 0, 0.3); }

.globalResourceCardImage {
  cursor: default; }

.globalResourceCardAction {
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 0px 0px 8px 8px; }

.globalResourceCardTitleHolder {
  margin-top: 10px;
  padding: 2px;
  height: 81px;
  min-height: 81px;
  max-height: 81px;
  flex-grow: 1;
  margin: auto 10px auto 18px;
  color: #101f47;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  justify-content: left;
  text-align: left;
  cursor: default;
  border: none;
  background-color: transparent;
  align-items: center;
  display: flex;
  max-height: fit-content; }

.globalResourceCardTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.globalResourceCardActionButton {
  width: 232px;
  min-width: 232px;
  max-width: 232px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 0px 0px 8px 8px;
  background-color: #009fd1; }

.globalResourceCardActionButton:hover {
  background-color: #009fd1;
  background-color: #2c8fbf;
  border-radius: 0px 0px 8px 8px; }
