@media (max-width: 1365.99px) {
  .filterResetButtonWrapper {
    visibility: hidden; } }

.filterResetButton {
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  background: #009fd1;
  border-radius: 6px;
  margin: 10px 0px 10px 1px;
  width: 194px;
  color: #ffffff;
  padding-bottom: 16px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  cursor: pointer; }
