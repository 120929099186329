#branding {
  background-color: transparent;
  margin-left: 0px;
  background: transparent; }
  @media (min-width: 1101px) {
    #branding {
      height: 100vh;
      min-height: 100vh;
      background-position: right;
      background-image: url("../../assets/images/login/login_intro.webp"); } }
  @media (min-width: 601px) and (max-width: 1100px) {
    #branding {
      height: 100vh;
      min-height: 100vh;
      background-position: center;
      background-image: url("../../assets/images/login/login_intro_tablet.webp"); } }
  @media (max-width: 600px) {
    #branding {
      height: 208px;
      min-height: 208px;
      background-position: center;
      background-image: url("../../assets/images/login/login_intro_mobile_375.webp"); } }
