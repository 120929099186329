DIV.ResetPasswordWrapper {
  width: 100%; }
  DIV.ResetPasswordWrapper .sectionTitle {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px; }
  DIV.ResetPasswordWrapper DIV.resetPasswordFeedbackText {
    font-size: 16px;
    margin-bottom: 20px; }
  DIV.ResetPasswordWrapper .largeParagraphBreak {
    width: 90%; }
  DIV.ResetPasswordWrapper .textInputCompHalf {
    max-width: 560px; }
  DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper {
    display: flex;
    flex-direction: row; }
    DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper DIV.resetPasswordSubmitFeedback {
      background-color: transparent;
      text-align: left;
      width: 100%; }
      DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper DIV.resetPasswordSubmitFeedback DIV.resetPasswordSubmitFeedbackPositive {
        color: #2c8fbf; }
      DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper DIV.resetPasswordSubmitFeedback DIV.resetPasswordSubmitFeedbackNegative {
        color: #e4333e; }
    DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper DIV.resetPasswordActionButtons {
      text-align: right;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      width: 100%; }
      DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper DIV.resetPasswordActionButtons .resetPasswordActionButtonsSignOut {
        text-align: left;
        justify-content: left;
        width: 50%; }
        DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper DIV.resetPasswordActionButtons .resetPasswordActionButtonsSignOut BUTTON {
          padding-left: 24px;
          min-width: 115px;
          width: 115px; }
      DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper DIV.resetPasswordActionButtons .resetPasswordActionButtonsChange {
        text-align: right;
        justify-content: right;
        width: 50%; }
        DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper DIV.resetPasswordActionButtons .resetPasswordActionButtonsChange BUTTON {
          padding-left: 24px;
          min-width: 185px; }
  DIV.ResetPasswordWrapper DIV.resetPasswordCurrent {
    background-color: transparent; }
  DIV.ResetPasswordWrapper DIV.resetPasswordNew {
    background-color: transparent; }
    DIV.ResetPasswordWrapper DIV.resetPasswordNew DIV.resetPasswordNewError {
      color: #e4333e;
      margin-top: -30px; }
    DIV.ResetPasswordWrapper DIV.resetPasswordNew DIV > DIV {
      margin-top: 20px; }
  DIV.ResetPasswordWrapper DIV.resetPasswordChallenge {
    background-color: transparent; }
    DIV.ResetPasswordWrapper DIV.resetPasswordChallenge DIV > DIV {
      margin-top: 20px; }
  DIV.ResetPasswordWrapper DIV.resetPasswordInputError {
    color: #e4333e;
    margin-top: -10px; }
  DIV.ResetPasswordWrapper DIV.resetPasswordInputChallengeError {
    color: #e4333e;
    margin-top: -30px;
    margin-bottom: 30px; }
  DIV.ResetPasswordWrapper SPAN.resetPasswordInputErrorText {
    color: #e4333e; }
  DIV.ResetPasswordWrapper DIV.resetPasswordSubmit {
    margin-top: 20px;
    margin-bottom: 30px; }
  DIV.ResetPasswordWrapper BUTTON.button-reverse-l {
    border: 1px solid #009fd1;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    outline: 1px dashed transparent;
    font-size: 16px;
    padding: 0px 32px 0px 32px;
    padding-right: 32px;
    width: inherit; }
  DIV.ResetPasswordWrapper BUTTON.button-default-l {
    border: 1px solid transparent;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    outline: 1px dashed transparent;
    font-size: 16px;
    padding: 0px 32px 0px 32px;
    width: inherit;
    margin-left: 0px; }
  DIV.ResetPasswordWrapper DIV.resetPasswordSubmitWrapper {
    text-align: right; }
