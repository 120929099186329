.instructorLedPageHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1d2332;
  padding: 0px 20px 0px 0px; }

.instructorLedPageSessionHeaderLabel {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #8c909b;
  margin-bottom: 15px; }

.instructorLedDetailWrapper {
  border: none;
  background-color: #ffffff; }

.instructorLedHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1d2332;
  margin-top: 20px; }

.instructorLedPageHeaderSeparator {
  background: #00cbe9;
  border-radius: 24px;
  height: 4px;
  width: 49px;
  margin-top: 7px;
  margin-bottom: 30px; }

.instructorLedPageSessionHeaderContainer {
  background-color: transparent; }

.instructorLedPageSessionContainer {
  background-color: #ffffff;
  border-radius: 8px; }
