.heroCourseContentWrapper {
  flex: auto;
  width: 100vw;
  min-height: 144px;
  max-height: 144px;
  background-color: transparent;
  background-image: url("../../../assets/images/components/hero/heroCourse.webp");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-color: #1d2332; }
  .heroCourseContentWrapper .heroCourseContent {
    border: none;
    margin: 65px 20px 78px 398px; }
  .heroCourseContentWrapper .heroCourseContentMessage {
    color: #ffffff;
    font-size: 48px;
    font-weight: 800; }
