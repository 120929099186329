.sessionHeaderContainer {
  background-color: #ffffff;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: auto 191px;
  border-radius: 8px 8px 8px 8px; }

.sessionHeaderTimeWrapper {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding: 15px;
  color: #1d2332;
  padding: 15px; }

.sessionHeaderStartDateFormatted {
  font-weight: normal; }

.sessionHeaderInstructorLabel {
  margin-right: 8px; }

.sessionHeaderLimitValue {
  margin-left: 8px;
  font-weight: 700; }

.sessionHeaderInstructorLedNameValue {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #101f47;
  margin-bottom: 7px; }

.sessionHeaderClassInfo {
  padding-top: 15px; }

.sessionCardDateTimeLabel {
  margin-right: 6px; }

.sessionCardLocationLabel {
  margin-right: 10px; }

.sessionHeaderImageWrapper {
  grid-column: 2 / 3;
  grid-row: 1 / 2; }

.sessionHeaderEndTimeValue {
  font-weight: normal; }

.sessionHeaderEndTimeZone {
  font-style: italic; }

.sessionHeaderDescription {
  margin-bottom: 15px; }
