.instructor-led-course-card-module__info___2wQ0Y {
  white-space: normal; }

.instructor-led-course-card-module__info_session_time___2ThXT {
  font-size: 18px; }

.card-module__card_bottom___2pcXZ {
  cursor: unset; }

.card-module__name___1kcxc:hover {
  cursor: pointer;
  text-decoration: underline; }

.cardContainer {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-left: 20px; }

.courseCard {
  border-radius: 8px;
  display: inline-block;
  box-shadow: 45px 45px 80px -5px rgba(0, 0, 0, 0.25);
  transition: box-shadow ease-in-out 0.6s;
  -ms-display: -ms-grid;
  display: grid;
  background-color: transparent; }

.courseCard:hover {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  box-shadow: 45px 45px 80px -5px rgba(0, 0, 0, 0.3); }
