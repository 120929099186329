.courseDetailWrapper {
  border: none; }

.courseHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1d2332;
  margin-top: 20px; }

.courseHeaderSeparator {
  background: #00cbe9;
  border-radius: 24px;
  height: 4px;
  width: 49px;
  margin-top: 7px;
  margin-bottom: 30px; }
