.cardListSortWrapper {
  flex: 1;
  float: right;
  margin-top: 11px; }
  @media (min-width: 1920px) {
    .cardListSortWrapper {
      margin-right: 238px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    .cardListSortWrapper {
      margin-right: 238px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    .cardListSortWrapper {
      margin-right: 132px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    .cardListSortWrapper {
      margin-right: 52px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    .cardListSortWrapper {
      margin-right: 23px;
      margin-left: 20px; } }
  @media (max-width: 359.99px) {
    .cardListSortWrapper {
      margin-right: 23px;
      margin-left: 20px; } }

.cardListSortDropdownHeader {
  float: right;
  margin-right: 40px; }

.cardListSortDropdown {
  background-color: transparent;
  color: #ffffff;
  margin: 16px 0px 20px 0px;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  border: 0.833333px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 24px;
  cursor: pointer;
  width: 210px; }

.cardListSortDropdown > option {
  background-color: #ffffff;
  color: #1d2332; }

.sortOrderHeader {
  font-weight: 800; }

.muiSearchSortTitle {
  color: silver; }

.muiSearchSort,
#muiSearchSort {
  color: white;
  text-align: center;
  height: 37px;
  padding-top: 17px;
  font-family: 'Lexand Deca', 'Google Sans', Khula, 'Open Sans', Lato, Helvetica, Verdana, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px; }
  .muiSearchSort .MuiSvgIcon-root,
  .muiSearchSort .MuiSvgIcon-fontSizeMedium,
  .muiSearchSort .makeStyles-icon-96,
  .muiSearchSort .MuiSelect-icon,
  .muiSearchSort .MuiSelect-iconOutlined,
  .muiSearchSort .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
  #muiSearchSort .MuiSvgIcon-root,
  #muiSearchSort .MuiSvgIcon-fontSizeMedium,
  #muiSearchSort .makeStyles-icon-96,
  #muiSearchSort .MuiSelect-icon,
  #muiSearchSort .MuiSelect-iconOutlined,
  #muiSearchSort .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    fill: transparent !important; }

.muiSearchSortLabel {
  color: #ffffff; }

DIV#menu-Sort LI.muiSearchSortItem {
  background-color: #ffffff;
  border-radius: 8px;
  margin: 8px 10px;
  font-family: 'Lexand Deca', 'Google Sans', 'Khula', 'Open Sans', 'Lato', Helvetica, Verdana, Arial, sans-serif;
  color: #101f47; }

DIV#menu-Sort LI.muiSearchSortItem:checked,
DIV#menu-Sort LI.Mui-selected {
  background-color: #eff1f5;
  border-radius: 8px;
  margin: 8px 10px;
  color: #00abdb; }

DIV#menu-Sort LI.muiSearchSortItem:hover {
  background-color: #e6f5fa;
  border-radius: 8px;
  margin: 8px 10px;
  color: #009fd1; }

DIV#menu-Sort .MuiPaper-root {
  border-radius: 15px !important;
  margin-top: 3px; }

SVG.MuiSelect-iconOpen,
SVG.MuiSelect-iconOpen:focus,
SVG.MuiSelect-iconOpen:active {
  margin-top: -5px; }

DIV#menu-Sort .MuiPaper-elevation {
  min-width: 180px !important;
  background-color: #ffffff !important; }

DIV#menu-Sort .MuiList-Root {
  margin-right: 0px !important; }
