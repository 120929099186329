.searchSideFilter {
  clear: both;
  border: 10px solid black;
  max-width: 240px;
  margin-left: 20px; }

.globalSearchResultsReturnedItems {
  margin-left: -10px;
  margin-top: -10px; }

.searchResultsContainer {
  float: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px; }

.searchResultItem {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  max-width: 232px;
  float: left;
  margin: 15px;
  border-radius: 8px 8px 0px 0px; }
  .searchResultItem DIV.globalResourceCard {
    margin-bottom: 0px; }

.searchResultItemResource {
  min-height: 20px; }

.globalSearchPageHeaderText {
  margin-left: 21px; }

.globalSearchPageResultsNotification {
  margin-left: 21px; }

.globalSearchPageResultsHeading {
  margin-top: 5px;
  font-weight: 400;
  font-size: 14pt; }

.globalSearchLoadMore {
  margin-left: 20px; }
