.bundlePageWrapper {
  width: 100%; }

.bundlePageTitle {
  font-size: 38px;
  font-weight: 800;
  line-height: 38px; }
  @media (min-width: 1920px) {
    .bundlePageTitle {
      margin: -135px 20px 100px 0px;
      color: #ffffff; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    .bundlePageTitle {
      margin: -135px 20px 100px 0px;
      color: #ffffff; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    .bundlePageTitle {
      margin: -135px 20px 100px 3px;
      color: #ffffff; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    .bundlePageTitle {
      margin: -135px 20px 100px 3px;
      color: #ffffff; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    .bundlePageTitle {
      margin: 285px 15px 25px 15px;
      color: #1d2332; } }
  @media (max-width: 359.99px) {
    .bundlePageTitle {
      margin: 285px 15px 25px 15px;
      color: #1d2332; } }

DIV.bundlePageImagePrimary > IMG {
  position: absolute;
  object-fit: cover; }
  @media (min-width: 1920px) {
    DIV.bundlePageImagePrimary > IMG {
      top: 116px;
      left: 50px;
      width: 326px;
      height: 225px;
      border-radius: 6px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.bundlePageImagePrimary > IMG {
      top: 116px;
      left: 50px;
      width: 326px;
      height: 225px;
      border-radius: 6px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV.bundlePageImagePrimary > IMG {
      top: 116px;
      left: 50px;
      width: 230px;
      height: 174px;
      border-radius: 6px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV.bundlePageImagePrimary > IMG {
      top: 116px;
      left: 50px;
      width: 230px;
      height: 174px;
      border-radius: 6px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.bundlePageImagePrimary > IMG {
      top: 68px;
      left: calc((100vw - 326px) / 2);
      width: 326px;
      height: 326px;
      border-radius: 8px 8px 8px 8px; } }
  @media (max-width: 359.99px) {
    DIV.bundlePageImagePrimary > IMG {
      top: 68px;
      left: calc((100vw - 312px) / 2);
      width: 312px;
      height: 312px;
      border-radius: 8px 8px 8px 8px; } }

.bundlePageTags {
  display: flex;
  align-items: center; }

div.bundlePageTags {
  padding-top: 10px; }

div.bundlePageTags > div > button {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #009fd1;
  background: rgba(55, 203, 236, 0.2);
  border-radius: 8px;
  align-items: center;
  margin: 0px 0px 0px 15px;
  padding: 12px 24px;
  border: none; }

.bundlePageIntroWrapper {
  background-color: #ffffff;
  box-shadow: 0px 16px 40px rgba(90, 101, 118, 0.04);
  border-radius: 8px;
  margin-top: 13px;
  padding-bottom: 13px; }

.bundlePageDescription {
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  color: #858994;
  padding: 15px; }
