.lessonWrapper {
  width: 100%;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto 191px;
  margin-bottom: 24px;
  border-radius: 8px; }
  .lessonWrapper .lessonCardColumnOne {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    padding: 24px; }
  .lessonWrapper .lessonInfo {
    background-color: transparent;
    flex-grow: 1; }
    .lessonWrapper .lessonInfo .lessonName {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #101f47;
      margin-bottom: 7px; }
    .lessonWrapper .lessonInfo .lessonDescription {
      color: #858994; }
      .lessonWrapper .lessonInfo .lessonDescription .lessonDescriptionShowMoreLink,
      .lessonWrapper .lessonInfo .lessonDescription .lessonDescriptionShowMoreLink:visited {
        font-weight: 600;
        color: #009fd1; }
  .lessonWrapper .lessonProgress {
    border: none;
    padding: 2px; }
    .lessonWrapper .lessonProgress DIV.lessonProgressTitle {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #101f47;
      margin-bottom: 7px; }
  .lessonWrapper .lessonCardColumnTwo {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    background-color: #f2f4f9; }
    .lessonWrapper .lessonCardColumnTwo .lessonImage {
      border: 0px;
      margin: 0px;
      width: 173px;
      min-width: 173px;
      max-width: 173px;
      height: 146px;
      min-height: 146px;
      max-height: 146px; }
    .lessonWrapper .lessonCardColumnTwo .lessonCardImageWrapper {
      border: 0px;
      margin: 0px;
      width: 191px;
      min-width: 191px;
      max-width: 191px;
      height: 162px;
      min-height: 162px;
      max-height: 162px;
      border-radius: 0px 8px 0px 0px; }
      .lessonWrapper .lessonCardColumnTwo .lessonCardImageWrapper .lessonCardImage {
        object-fit: cover;
        width: 191px;
        min-width: 191px;
        max-width: 191px;
        height: 162px;
        min-height: 162px;
        max-height: 162px;
        border-radius: 0px 8px 0px 0px; }
    .lessonWrapper .lessonCardColumnTwo .lessonCardActionButton {
      border: none;
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      width: 173px;
      min-width: 173px;
      max-width: 173px; }
