.no-backgroundColor {
  background: unset; }

DIV.checkoutWrapper {
  height: 100%; }
  @media (min-width: 801px) {
    DIV.checkoutWrapper {
      width: 700px; } }
  @media (max-width: 800px) {
    DIV.checkoutWrapper {
      width: 95%; } }

DIV.checkoutFormPersonal {
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif; }
  DIV.checkoutFormPersonal .checkoutFormPersonalIntro {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #1d2332;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: row; }
    DIV.checkoutFormPersonal .checkoutFormPersonalIntro DIV.MuiAvatar-root {
      font-size: 17px;
      margin-right: 10px; }
    DIV.checkoutFormPersonal .checkoutFormPersonalIntro .checkoutFormPersonalIntroStepNumber {
      background: #009fd1;
      border-radius: 50%;
      color: #ffffff;
      height: 1.4em;
      position: relative;
      width: 1.4em;
      float: left;
      margin-right: 15px; }
      DIV.checkoutFormPersonal .checkoutFormPersonalIntro .checkoutFormPersonalIntroStepNumber P {
        hyphens: auto;
        text-align: center;
        color: #ffffff;
        float: left;
        padding-left: 6px;
        font-weight: 700;
        padding-top: 2px;
        padding-left: 8px; }
    DIV.checkoutFormPersonal .checkoutFormPersonalIntro .checkoutFormPersonalIntroText {
      float: left;
      margin-top: 3px; }
  @media (min-width: 801px) {
    DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoNameFields {
      float: left; }
      DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoNameFields DIV.checkoutFormPersonalInfoNameFieldsFirst {
        float: left;
        width: 345px; }
      DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoNameFields DIV.checkoutFormPersonalInfoNameFieldsLast {
        float: left;
        width: 345px;
        margin-left: 10px; }
    DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoRegionFields {
      float: left;
      width: 100%; }
      DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoRegionFields DIV.checkoutFormBillingLocationItem {
        float: left;
        width: 49%;
        max-width: 49%; }
      DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoRegionFields DIV.checkoutFormPersonalInfoRegionFieldsArea {
        float: left;
        width: 49%;
        margin-left: 12px;
        max-width: 49%; } }
  @media (max-width: 800px) {
    DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoNameFields {
      display: flex;
      flex-direction: column; }
      DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoNameFields DIV.checkoutFormPersonalInfoNameFieldsFirst {
        float: left;
        width: 100%; }
      DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoNameFields DIV.checkoutFormPersonalInfoNameFieldsLast {
        float: left;
        width: 100%; }
    DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoRegionFields {
      display: flex;
      flex-direction: column;
      width: 100%; }
      DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoRegionFields DIV.checkoutFormBillingLocationItem {
        float: left;
        width: 100%; }
      DIV.checkoutFormPersonal DIV.checkoutFormPersonalInfoRegionFields DIV.checkoutFormPersonalInfoRegionFieldsArea {
        float: left;
        width: 100%; } }

@media (min-width: 801px) {
  DIV.checkoutFormBilling {
    float: left;
    width: 100%; }
    DIV.checkoutFormBilling DIV.checkoutFormPersonalInfoRegionFieldsCity {
      float: left;
      width: 49%;
      max-width: 49%;
      margin-right: 12px; }
    DIV.checkoutFormBilling .checkoutFormBillingLocationPostal {
      float: left;
      width: 49%;
      max-width: 49%; } }

@media (max-width: 801px) {
  DIV.checkoutFormBilling {
    display: flex;
    flex-direction: column;
    width: 100%; }
    DIV.checkoutFormBilling DIV.checkoutFormPersonalInfoRegionFieldsCity {
      float: left;
      width: 100%;
      margin-right: 12px; }
    DIV.checkoutFormBilling .checkoutFormBillingLocationPostal {
      float: left;
      width: 100%; } }

DIV.clearFloat {
  clear: both; }

.checkoutFormSubmitButtonEnabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 32px 16px 32px;
  position: static;
  width: 100%;
  height: 48px;
  background: #009fd1;
  border-radius: 6px;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 24px 0px;
  color: #ffffff;
  font-weight: 500;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-size: 18px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif; }

.checkoutFormSubmitButtonDisabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 32px 16px 32px;
  position: static;
  width: 100%;
  height: 48px;
  background: #99d9ed;
  border-radius: 6px;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 24px 0px;
  color: #ffffff;
  font-weight: 500;
  border: none;
  font-size: 18px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif; }

.checkoutFormPaymentStepHeader {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #1d2332;
  margin-bottom: 20px;
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row; }
  .checkoutFormPaymentStepHeader DIV.MuiAvatar-root {
    font-size: 17px;
    margin-right: 10px; }
  .checkoutFormPaymentStepHeader .checkoutFormPaymentStepNumber {
    background: #858994;
    border-radius: 50%;
    color: #ffffff;
    height: 1.4em;
    position: relative;
    width: 1.4em;
    float: left;
    margin-right: 15px; }
    .checkoutFormPaymentStepHeader .checkoutFormPaymentStepNumber P {
      hyphens: auto;
      text-align: center;
      color: #ffffff;
      float: left;
      padding-left: 6px;
      font-weight: 700;
      padding-top: 2px;
      padding-left: 8px; }
