DIV.checkoutSummaryHosted {
  margin-top: 5px; }

DIV.checkoutSummary {
  margin-top: 0px; }

DIV.checkoutSummary,
DIV.checkoutSummaryHosted {
  background-color: #ffffff;
  /*
  DIV.cartCoupon {
    background-color: transparent;
    margin-left: 28px;
    margin-bottom: 25px;
    margin-top: 12px;

    DIV.cartCouponHeader {
      background-color: transparent;
      font-size: 13px;
      line-height: 15px;
      color: #1d2332;
    }
    DIV.cartCouponCodeInput {
      background-color: transparent;

      INPUT.cartCouponCodeInputText {
        background-color: transparent;
        border: 1px solid rgba(77, 89, 120, 0.1);
        box-sizing: border-box;
        border-radius: 6px;
        width: 231px;
        height: 55px;
        margin-right: 18px;
        font-size: 16px;
        line-height: 24px;
        color: #1d2332;
        padding-left: 15px;
        padding-right: 15px;
      }
      INPUT.cartCouponCodeInputTextError {
        background-color: transparent;
        border: 1px solid #e4333e;
        box-sizing: border-box;
        border-radius: 6px;
        width: 231px;
        height: 55px;
        margin-right: 18px;
        font-size: 16px;
        line-height: 24px;
        color: #1d2332;
        padding-left: 15px;
        padding-right: 15px;
      }
      BUTTON.cartCouponEnabled {
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        background: #009fd1;
        border-radius: 6px;
        color: #ffffff;
        border: none;
      }
      BUTTON.cartCouponDisabled {
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        background: #009fd1;
        opacity: 0.4;
        border-radius: 6px;
        color: #ffffff;
        border: none;
      }
    }
    DIV.cartCouponErrorMessage {
      background-color: transparent;
      font-size: 13px;
      line-height: 15px;
      color: #e4333e;
      margin-top: 8px;
    }
  }
  */ }
  DIV.checkoutSummary DIV.checkoutSummaryPurchases,
  DIV.checkoutSummaryHosted DIV.checkoutSummaryPurchases {
    padding: 28px 28px 10px 28px; }
    DIV.checkoutSummary DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper,
    DIV.checkoutSummaryHosted DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper {
      background-color: transparent;
      width: 100%;
      display: grid; }
      DIV.checkoutSummary DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageCartMiniCardImage,
      DIV.checkoutSummaryHosted DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageCartMiniCardImage {
        color: indigo;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: span 3; }
        DIV.checkoutSummary DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageCartMiniCardImage IMG.checkoutPageCartMiniImage,
        DIV.checkoutSummaryHosted DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageCartMiniCardImage IMG.checkoutPageCartMiniImage {
          max-width: 55px;
          min-width: 55px;
          height: 55px;
          max-height: 55px;
          min-height: 55px;
          border-radius: 8px 8px 8px 8px;
          object-fit: cover; }
      DIV.checkoutSummary DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageCartMiniCardName,
      DIV.checkoutSummaryHosted DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageCartMiniCardName {
        max-width: 192px;
        min-width: 192px;
        padding: 6px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #1d2332;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: span 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 32px;
        white-space: nowrap; }
      DIV.checkoutSummary DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageMiniCardDescription,
      DIV.checkoutSummaryHosted DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageMiniCardDescription {
        padding: 12px 10px 0px 6px;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #4d5978;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: span 2; }
        DIV.checkoutSummary DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageMiniCardDescription P,
        DIV.checkoutSummaryHosted DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageMiniCardDescription P {
          max-width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
      DIV.checkoutSummary DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageCartMiniCardPrice,
      DIV.checkoutSummaryHosted DIV.checkoutSummaryPurchases DIV.checkoutPageCartMiniCardWrapper DIV.checkoutPageCartMiniCardPrice {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #1d2332;
        float: right;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: span 3;
        margin-top: 6px;
        white-space: nowrap; }
  DIV.checkoutSummary DIV.checkoutSummaryTotals,
  DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals {
    padding: 10px 28px 28px 28px;
    display: grid; }
    DIV.checkoutSummary DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsSubtotalLabel,
    DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsSubtotalLabel {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: span 2;
      margin-bottom: 37px;
      font-size: 16px;
      line-height: 24px;
      color: #5c6170; }
    DIV.checkoutSummary DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsSubtotalValue,
    DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsSubtotalValue {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: span 2;
      margin-bottom: 37px;
      text-align: right;
      font-size: 16px;
      line-height: 24px;
      color: #5c6170; }
    DIV.checkoutSummary DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTaxLabel,
    DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTaxLabel {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: span 3;
      margin-bottom: 27px;
      font-size: 16px;
      line-height: 24px;
      color: #5c6170; }
    DIV.checkoutSummary DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTaxValue,
    DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTaxValue {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: span 3;
      margin-bottom: 27px;
      text-align: right;
      font-size: 16px;
      line-height: 24px;
      color: #5c6170; }
    DIV.checkoutSummary DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTotalLabel,
    DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTotalLabel {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: span 4;
      margin-bottom: 27px;
      margin-top: 17px;
      font-weight: bold;
      font-size: 32px;
      line-height: 24px;
      color: #101f47; }
    DIV.checkoutSummary DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTotalValue,
    DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTotalValue {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: span 4;
      margin-bottom: 27px;
      margin-top: 17px;
      text-align: right;
      font-weight: bold;
      font-size: 32px;
      line-height: 24px;
      color: #101f47; }
    DIV.checkoutSummary DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTerms,
    DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTerms {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 4;
      grid-row-end: span 5;
      margin-top: 52px;
      font-size: 13px;
      line-height: 25px;
      color: #4a4a4a; }
      DIV.checkoutSummary DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTerms A,
      DIV.checkoutSummary DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTerms A:visited,
      DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTerms A,
      DIV.checkoutSummaryHosted DIV.checkoutSummaryTotals DIV.checkoutSummaryTotalsTerms A:visited {
        color: #4a4a4a;
        text-decoration: underline; }

.checkoutSummaryLoader {
  height: 1px;
  width: 1px; }

.headerCartIconMenuActionButtons {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: span 5;
  margin-top: 52px;
  display: flex;
  flex-direction: 'row'; }
  .headerCartIconMenuActionButtons BUTTON {
    font-size: 10pt; }
  .headerCartIconMenuActionButtons BUTTON.button-reverse-l {
    width: 167px;
    border: 1px solid #009fd1;
    font-size: 10pt;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    padding: 0px;
    outline: 1px dashed transparent; }
  .headerCartIconMenuActionButtons BUTTON.button-default-l {
    width: 167px;
    border: none;
    outline: 1px dashed transparent;
    margin: 4px 0px 4px 4px !important; }

.cardListRemoveMini {
  background-color: transparent;
  margin-left: -10px;
  margin-top: -10px;
  width: 10px;
  height: 10px;
  border: none; }

.cardListRemoveMiniImg {
  margin-top: 36px;
  margin-left: -98px;
  -webkit-transform: translate3d(0, 0, 0);
  cursor: pointer; }

.checkoutPageCartCloseButtonWrapper {
  margin-top: -100px;
  z-index: 0; }

.cartSubHeaderMini {
  margin-right: 50px;
  margin-left: 21px;
  margin-top: 20px; }

.cartSummaryHeaderSeperator {
  background-color: #e2e2e2;
  width: 87%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 22px; }

.cartSummaryHostedHeader {
  margin-top: 7px; }
