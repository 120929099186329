DIV.profileInitialBlock {
  position: absolute; }
  @media (min-width: 1920px) {
    DIV.profileInitialBlock {
      top: 150px;
      left: 388px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.profileInitialBlock {
      top: 150px;
      left: 388px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV.profileInitialBlock {
      top: 150px;
      left: 80px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV.profileInitialBlock {
      top: 150px;
      left: 40px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.profileInitialBlock {
      left: calc((100vw - 198px) / 2);
      top: 50px;
      transform: scale(0.75); } }
  @media (max-width: 359.99px) {
    DIV.profileInitialBlock {
      left: calc((100vw - 198px) / 2);
      top: 50px;
      transform: scale(0.75); } }
  DIV.profileInitialBlock DIV.MuiAvatar-root {
    background-image: url("../assets/images/icons/whiteEdit.svg");
    background-position: 160px 160px;
    background-repeat: no-repeat;
    position: relative; }
  DIV.profileInitialBlock DIV.MuiAvatar-root:before {
    content: '';
    background: url("../assets/images/content/profile/logoGhosting.webp") no-repeat;
    background-size: 64%;
    background-position: 35px 25px;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1; }

DIV.profileImageBlock {
  position: absolute; }
  @media (min-width: 1920px) {
    DIV.profileImageBlock {
      top: 150px;
      left: 388px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.profileImageBlock {
      top: 150px;
      left: 388px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV.profileImageBlock {
      top: 150px;
      left: 80px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV.profileImageBlock {
      top: 150px;
      left: 40px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.profileImageBlock {
      left: calc((100vw - 198px) / 2);
      top: 50px;
      transform: scale(0.75); } }
  @media (max-width: 359.99px) {
    DIV.profileImageBlock {
      left: calc((100vw - 198px) / 2);
      top: 50px;
      transform: scale(0.75); } }
  DIV.profileImageBlock DIV.MuiAvatar-root {
    /* requires: avatar editing implementation: E1-972 */
    /*
    background-image: url('../assets/images/icons/whiteEdit.svg');
    background-position: 160px 160px;
    background-repeat: no-repeat;
    position: relative;
    */
    border: none; }

@media (min-width: 1920px) {
  DIV.profileName {
    margin-top: -200px;
    margin-bottom: 100px;
    margin-left: 234px;
    height: 150px;
    white-space: nowrap; } }

@media (min-width: 1366px) and (max-width: 1919.99px) {
  DIV.profileName {
    margin-top: -200px;
    margin-bottom: 100px;
    margin-left: 234px;
    height: 150px;
    width: 750px; } }

@media (min-width: 768px) and (max-width: 1365.99px) and (orientation: portrait) {
  DIV.profileName {
    margin-top: -200px;
    margin-bottom: 100px;
    margin-left: 234px;
    height: 150px;
    width: 500px; } }

@media (min-width: 768px) and (max-width: 1365.99px) and (orientation: landscape) {
  DIV.profileName {
    margin-top: -200px;
    margin-bottom: 100px;
    margin-left: 234px;
    height: 150px;
    width: 750px; } }

@media (min-width: 360px) and (max-width: 767.99px) {
  DIV.profileName {
    margin-top: -110px;
    margin-bottom: 100px;
    text-align: center;
    height: 150px;
    width: calc(100vw - 40px) !important;
    line-height: normal;
    max-height: 200px; } }

@media (max-width: 359.99px) {
  DIV.profileName {
    margin-top: -110px;
    margin-bottom: 100px;
    text-align: center;
    height: 150px;
    width: calc(100vw - 40px) !important;
    line-height: normal;
    max-height: 200px; } }

DIV.profileName SPAN.profileFirstName {
  color: #ffffff;
  font-size: 36px;
  font-weight: 800;
  text-shadow: 9px -6px 28px #ffffff55; }
  @media (min-width: 768px) {
    DIV.profileName SPAN.profileFirstName {
      line-height: 44px; } }
  @media (max-width: 767.99px) {
    DIV.profileName SPAN.profileFirstName {
      line-height: 20px; } }

DIV.profileName SPAN.profileLastName {
  font-size: 36px;
  font-weight: 800;
  color: #009fd1;
  text-shadow: 9px -6px 28px #009fd155; }
  @media (min-width: 768px) {
    DIV.profileName SPAN.profileLastName {
      line-height: 44px; } }
  @media (max-width: 767.99px) {
    DIV.profileName SPAN.profileLastName {
      line-height: 20px;
      overflow: hidden; } }

DIV.profilePageEditor {
  display: flex;
  flex-direction: column; }

.profileinitialBlockAvatarText {
  opacity: 1 !important;
  color: white !important;
  z-index: 2; }
