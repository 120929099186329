.messageItemContainer {
  border: none;
  width: 100%; }
  .messageItemContainer BUTTON.messageItemButton {
    border: none;
    width: 100%;
    background-color: transparent; }
    .messageItemContainer BUTTON.messageItemButton .messageItemWrapper {
      display: grid;
      grid-template-columns: 70px auto 173px;
      grid-template-rows: 15px auto;
      width: 100%;
      background-color: transparent;
      margin-bottom: 14px;
      border-bottom: 1px solid #e7e7e7; }
      .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemIcon {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: span 3;
        border: none;
        text-align: left;
        margin-bottom: 20px; }
        .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemIcon SPAN > DIV {
          border-radius: 0 !important;
          background-color: #f2f4f9; }
        .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemIcon .messageItemImage {
          height: 34px;
          width: 34px;
          border-radius: 8px;
          opacity: 0.8;
          object-fit: cover; }
      .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .activityItemIcon {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: span 3;
        border: none;
        text-align: left;
        margin-bottom: 1px; }
        .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .activityItemIcon SPAN > DIV {
          border-radius: 0 !important;
          background-color: #f2f4f9; }
        .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .activityItemIcon .messageItemImage {
          height: 50px;
          width: 50px;
          border-radius: 8px;
          object-fit: cover; }
      .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemTitle {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: span 2;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #101f47;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemDescription {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: span 3;
        color: #858994;
        font-size: 13px;
        line-height: 19px;
        text-align: left;
        margin-top: 8px;
        margin-bottom: 8px; }
        .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemDescription .messageItemDescriptionShowMore,
        .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemDescription .messageItemDescriptionShowMore:visited {
          color: #009fd1; }
      .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemShortDescriptionWrapper {
        display: flex;
        flex-direction: row; }
      .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemLinkButton {
        background-color: transparent;
        color: #009fd1;
        border: none;
        cursor: pointer;
        text-decoration: underline; }
      .messageItemContainer BUTTON.messageItemButton .messageItemWrapper .messageItemFreshness {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: span 3;
        text-align: right;
        font-size: 15px;
        margin: 5px; }

.activitiesWrapper {
  margin-top: 0px; }
