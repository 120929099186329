.lmsItemGroupWrapper {
  width: 100%;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto 190px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #ffffff; }
  .lmsItemGroupWrapper .lmsItemColumnOne {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    padding: 24px; }
    .lmsItemGroupWrapper .lmsItemColumnOne .lmsItemInfo {
      background-color: transparent;
      flex-grow: 1; }
      .lmsItemGroupWrapper .lmsItemColumnOne .lmsItemInfo .lmsItemName {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #101f47;
        margin-bottom: 7px; }
    .lmsItemGroupWrapper .lmsItemColumnOne .lmsItemDescription {
      color: #858994; }
    .lmsItemGroupWrapper .lmsItemColumnOne .lmsItemProgress {
      border: none;
      padding: 2px; }
      .lmsItemGroupWrapper .lmsItemColumnOne .lmsItemProgress DIV.lmsItemProgressTitle {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #101f47;
        margin-bottom: 7px; }
  .lmsItemGroupWrapper .lmsItemColumnTwo {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column; }
    .lmsItemGroupWrapper .lmsItemColumnTwo .lmsItemImageWrapper {
      border: 0px;
      margin: 0px;
      width: 191px;
      min-width: 191px;
      max-width: 191px;
      height: 162px;
      min-height: 162px;
      max-height: 162px;
      border-radius: 0px 8px 0px 0px; }
      .lmsItemGroupWrapper .lmsItemColumnTwo .lmsItemImageWrapper .lmsItemImage {
        object-fit: cover;
        width: 191px;
        min-width: 191px;
        max-width: 191px;
        height: 162px;
        min-height: 162px;
        max-height: 162px;
        border-radius: 0px 8px 0px 0px; }
    .lmsItemGroupWrapper .lmsItemColumnTwo .lmsItemActionButton {
      border: none;
      width: 191px;
      min-width: 191px;
      max-width: 191px; }
