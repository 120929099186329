@media screen {
  .printHeader,
  .printHeaderWrapper,
  .printHeaderDts,
  .transcriptsPage {
    display: none; }
  DIV.transcriptsPageHeader {
    display: flex;
    flex-direction: row;
    width: 100%; }
    DIV.transcriptsPageHeader .headerPageTitle {
      color: #ffffff;
      font-size: 48px;
      font-weight: 800;
      line-height: 38px; } }
    @media screen and (min-width: 1920px) {
      DIV.transcriptsPageHeader .headerPageTitle {
        margin: -135px 20px 100px 0px; } }
    @media screen and (min-width: 1366px) and (max-width: 1919.99px) {
      DIV.transcriptsPageHeader .headerPageTitle {
        margin: -135px 20px 100px 0px; } }
    @media screen and (min-width: 1024px) and (max-width: 1365.99px) {
      DIV.transcriptsPageHeader .headerPageTitle {
        margin: -135px 20px 100px 0px; } }
    @media screen and (min-width: 768px) and (max-width: 1023.99px) {
      DIV.transcriptsPageHeader .headerPageTitle {
        margin: -135px 20px 100px 0px; } }
    @media screen and (min-width: 360px) and (max-width: 767.99px) {
      DIV.transcriptsPageHeader .headerPageTitle {
        margin: -135px 20px 100px 0px; } }
    @media screen and (max-width: 359.99px) {
      DIV.transcriptsPageHeader .headerPageTitle {
        margin: -135px 20px 100px 0px; } }

@media screen {
  DIV.headerPageTitle {
    color: #ffffff;
    font-size: 48px;
    font-weight: 800;
    margin-top: -130px;
    margin-bottom: 85px; }
  DIV.headerPagePrintButtonWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: -20px; }
  DIV.headerPagePrintButtonSpacer {
    margin: auto; }
  DIV.headerPagePrintButton {
    margin-bottom: 0px;
    text-align: right;
    justify-content: flex-end;
    width: 100px; }
    DIV.headerPagePrintButton DIV.printButtonTextWrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      text-align: center;
      justify-content: center; }
      DIV.headerPagePrintButton DIV.printButtonTextWrapper DIV {
        margin-bottom: 2px; }
    DIV.headerPagePrintButton DIV.printWaitSpinner {
      margin: auto; }
    DIV.headerPagePrintButton BUTTON.tabHeaderContainerLinkButtonEnabled {
      width: 200px;
      margin-left: -98px;
      padding: 8px 24px 10px 24px; }
      DIV.headerPagePrintButton BUTTON.tabHeaderContainerLinkButtonEnabled DIV > DIV {
        margin-bottom: 5px; }
    DIV.headerPagePrintButton BUTTON.tabHeaderContainerLinkButtonDisabled {
      width: 200px;
      margin-left: -98px;
      background-color: #94d4eb;
      border: none;
      border-radius: 6px;
      cursor: not-allowed;
      padding: 8px 24px 10px 24px; }
      DIV.headerPagePrintButton BUTTON.tabHeaderContainerLinkButtonDisabled DIV > DIV {
        margin-bottom: 5px; }
  .sectionHeader {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #101f47;
    margin-bottom: 20px;
    margin-top: 35px; }
  DIV.transcriptCoursesDataTable {
    border: none; }
  DIV.statsBoxWrapper {
    margin-top: -50px; }
  DIV.statsBox {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 16px 40px rgba(90, 101, 118, 0.04);
    border-radius: 8px;
    padding: 20px; }
    DIV.statsBox DIV.statsBoxTitle {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 14px; }
    DIV.statsBox DIV.statsBoxText {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #858994; }
    DIV.statsBox DIV.statsBoxUsername {
      visibility: hidden;
      height: 100%; }
    DIV.statsBox DIV.statsBoxDepartment {
      height: 100%; }
    DIV.statsBox DIV.statsBoxEmail {
      height: 100%; }
    DIV.statsBox DIV.statsBoxCredits {
      height: 100%; }
    DIV.statsBox DIV.statsBoxItem {
      flex-grow: 1; }
    DIV.statsBox DIV.statsBoxSection {
      border: none; }
  DIV.transcriptRowHeader {
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;
    margin-bottom: 20px;
    background-color: transparent; }
    DIV.transcriptRowHeader DIV.transcriptRowHeader {
      font-weight: 500;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderTitle {
      font-weight: 500;
      width: 32%;
      flex: 0 0 auto;
      font-size: 16px;
      padding-left: 20px;
      vertical-align: top; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderStatus {
      font-weight: 500;
      width: 18%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderScore {
      font-weight: 500;
      width: 10%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderEnrollment {
      font-weight: 500;
      width: 15%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderCompletion {
      font-weight: 500;
      width: 15%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderCredits {
      font-weight: 500;
      width: 10%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader BUTTON.transcriptRowHeaderSortButton {
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 13px;
      line-height: 30px;
      color: #858994;
      font-family: 'Lexand Deca', 'Lato', 'Open Sans', 'Khula', Helvetica, Verdana, Arial, sans-serif; }
  .transcriptRowSpacer {
    min-height: 7px; }
  DIV.transcriptRowDataWrapper {
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;
    background-color: #ffffff;
    margin-bottom: -12px;
    box-shadow: 0px 16px 40px rgba(90, 101, 118, 0.04);
    border-radius: 8px; }
    DIV.transcriptRowDataWrapper DIV.transcriptRowData {
      display: flex;
      flex-direction: row;
      padding: 5px;
      width: 100%;
      background-color: #ffffff;
      height: 84px; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowTitle {
        font-weight: 400;
        width: 32%;
        flex: 0 0 auto;
        font-size: 16px;
        font-weight: 700;
        margin: auto;
        padding-left: 20px; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowStatus {
        font-weight: 400;
        width: 18%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto; }
        DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowStatus DIV.transcriptRowStatusText {
          font-weight: 700;
          color: #009fd1;
          font-size: 13px;
          padding-left: 3px; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowScore {
        font-weight: 400;
        width: 10%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto;
        color: #858994; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowEnrollment {
        font-weight: 400;
        width: 15%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto;
        color: #858994;
        padding-left: 5px; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowCompletion {
        font-weight: 400;
        width: 15%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto;
        color: #858994;
        padding-left: 5px; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowCredits {
        font-weight: 400;
        width: 10%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto;
        color: #858994; }
  IMG.sortIcon {
    margin-left: 3px; }
  DIV.transcriptRowHeaderItemButtonContainer {
    display: flex;
    flex-direction: row; }
  .transcriptRowHeaderItemButtonContainerImage {
    margin-top: 3px;
    cursor: pointer; }
  .transcriptsLoadMoreWrapper {
    margin-left: -20px;
    margin-top: 5px; } }

@media print {
  DIV.transcriptsPageHeader {
    display: flex;
    flex-direction: row; }
  DIV.headerPageTitle {
    color: #000000;
    font-size: 38px;
    font-weight: 800;
    line-height: 38px;
    margin: 0px 20px 12px 0px; }
  DIV.headerPagePrintButton {
    display: none; }
  .printHeaderWrapper {
    border: none; }
    .printHeaderWrapper .printHeader {
      display: flex;
      flex-direction: row;
      margin-bottom: -20px;
      font-size: 20pt;
      font-weight: 700;
      width: 100%; }
      .printHeaderWrapper .printHeader DIV.printHeaderLogo > IMG {
        width: 32px;
        height: 32px; }
      .printHeaderWrapper .printHeader DIV.printHeaderText {
        margin-top: auto;
        margin-bottom: 10px;
        margin-left: 10px; }
    .printHeaderWrapper DIV.printHeaderDts {
      text-align: right;
      justify-content: flex-end;
      font-size: 10pt;
      font-weight: 400;
      font-style: italic;
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 50px;
      padding-bottom: 5px; }
  .sectionHeader {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #101f47;
    margin-bottom: 20px;
    margin-top: 35px;
    display: none; }
  DIV.transcriptCoursesDataTable {
    border: none; }
  DIV.statsBox {
    display: flex;
    flex-direction: row;
    background: transparent;
    border-radius: 8px;
    padding: 20px 20px 20px 0px;
    margin-bottom: 12px; }
    DIV.statsBox DIV.statsBoxTitle {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px; }
    DIV.statsBox DIV.statsBoxText {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #858994; }
    DIV.statsBox DIV.statsBoxUsername {
      visibility: hidden;
      height: 100%; }
    DIV.statsBox DIV.statsBoxDepartment {
      height: 100%; }
    DIV.statsBox DIV.statsBoxEmail {
      height: 100%; }
    DIV.statsBox DIV.statsBoxCredits {
      height: 100%; }
    DIV.statsBox DIV.statsBoxItem {
      flex-grow: 1; }
    DIV.statsBox DIV.statsBoxSection {
      border: none; }
  DIV.transcriptRowHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid #d8d8d8; }
    DIV.transcriptRowHeader DIV.transcriptRowHeader {
      font-weight: 500;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderTitle {
      font-weight: 500;
      width: 32%;
      flex: 0 0 auto;
      font-size: 16px;
      vertical-align: top; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderStatus {
      font-weight: 500;
      width: 18%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderScore {
      font-weight: 500;
      width: 10%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderEnrollment {
      font-weight: 500;
      width: 15%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderCompletion {
      font-weight: 500;
      width: 15%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader DIV.transcriptRowHeaderCredits {
      font-weight: 500;
      width: 10%;
      flex: 0 0 auto;
      font-size: 16px; }
    DIV.transcriptRowHeader BUTTON.transcriptRowHeaderSortButton {
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 13px;
      line-height: 30px;
      color: #858994;
      font-family: 'Lexand Deca', 'Lato', 'Open Sans', 'Khula', Helvetica, Verdana, Arial, sans-serif; }
  .transcriptRowSpacer {
    min-height: 2px;
    height: 6px; }
  DIV.transcriptRowDataWrapper {
    display: flex;
    flex-direction: row;
    padding: 5px 5px 5px 0px;
    width: 100%;
    background-color: transparent;
    margin-bottom: -15px;
    border-radius: 8px; }
    DIV.transcriptRowDataWrapper DIV.transcriptRowData {
      display: flex;
      flex-direction: row;
      width: 100%;
      background-color: transparent;
      border-bottom: 1px solid #d8d8d8; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowTitle {
        font-weight: 400;
        width: 32%;
        flex: 0 0 auto;
        font-size: 16px;
        font-weight: 700;
        margin: auto; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowStatus {
        font-weight: 400;
        width: 18%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto; }
        DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowStatus DIV.transcriptRowStatusText {
          font-weight: 700;
          color: #009fd1;
          font-size: 16px;
          padding-left: 3px; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowScore {
        font-weight: 400;
        width: 10%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto;
        color: #858994; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowEnrollment {
        font-weight: 400;
        width: 15%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto;
        color: #858994;
        padding-left: 5px; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowCompletion {
        font-weight: 400;
        width: 15%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto;
        color: #858994;
        padding-left: 5px; }
      DIV.transcriptRowDataWrapper DIV.transcriptRowData DIV.transcriptRowCredits {
        font-weight: 400;
        width: 10%;
        flex: 0 0 auto;
        font-size: 16px;
        margin: auto;
        color: #858994; }
  IMG.sortIcon {
    margin-left: 3px; }
  DIV.transcriptRowHeaderItemButtonContainer {
    display: flex;
    flex-direction: row; }
  .transcriptRowHeaderItemButtonContainerImage {
    display: none;
    margin-top: 3px;
    cursor: pointer; }
  .transcriptsLoadMoreWrapper {
    margin-left: -20px;
    margin-top: 5px; }
  BUTTON.transcriptRowHeaderSortButton:first-child {
    border: none;
    margin-left: 0px; } }
