.select-disabled-white-background {
  background: #fff !important; }

.selectInputComp {
  color: #1d2332;
  font-family: inherit;
  line-height: normal;
  outline: none;
  padding: 16px 27px 15px 26px;
  background: #ffffff;
  border: 1px solid rgba(77, 89, 120, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px;
  width: 100%;
  height: 60px; }

SPAN.requiredFieldIdentifier {
  color: red;
  font-weight: 800; }
