.checkoutPage {
  width: 100%; }
  @media (min-width: 1366px) {
    .checkoutPage {
      display: flex;
      flex-direction: row; } }
  @media (max-width: 1365.99px) {
    .checkoutPage {
      display: flex;
      flex-direction: column-reverse; } }
  .checkoutPage .checkoutPageBillingDetails {
    float: left;
    width: 100%; }
  @media (min-width: 801px) {
    .checkoutPage .checkoutPageSummary {
      background-color: #ffffff;
      width: 415px;
      min-width: 415px;
      max-width: 415px;
      margin-top: -158px;
      float: right;
      height: 100vh;
      margin-right: 8px; } }
  @media (max-width: 800px) {
    .checkoutPage .checkoutPageSummary {
      width: 102%;
      margin-top: -90px;
      float: right;
      margin-right: 8px;
      margin-bottom: 20px;
      margin-left: -40px; } }

.checkoutPageHeaderSpacer {
  height: 35px; }
  @media (min-width: 1366px) {
    .checkoutPageHeaderSpacer {
      height: 35px; } }
  @media (max-width: 1365.99px) {
    .checkoutPageHeaderSpacer {
      height: 5px; } }
