.catalogPageNoResultsContainer {
  background-color: #ffffff;
  box-shadow: 0px 16px 40px rgba(90, 101, 118, 0.04);
  border-radius: 8px;
  padding: 40px; }
  .catalogPageNoResultsContainer .catalogPageNoResultsIcon {
    margin-bottom: 20px;
    text-align: center; }
    .catalogPageNoResultsContainer .catalogPageNoResultsIcon .catalogPageNoResultsImage {
      height: 50px;
      width: 50px; }
  .catalogPageNoResultsContainer .catalogPageNoResultsHeader {
    border: none; }
  .catalogPageNoResultsContainer .catalogPageNoResultsBtnWrapper {
    text-align: right; }
    .catalogPageNoResultsContainer .catalogPageNoResultsBtnWrapper .catalogPageNoResultsButton {
      margin-top: 13px;
      line-height: 30px;
      color: #858994;
      padding: 5px 10px;
      justify-content: center;
      align-items: center;
      background-color: #009fd1;
      border-radius: 6px;
      margin: 25px 10px 0px 10px;
      color: #ffffff;
      border: none;
      cursor: pointer; }
      .catalogPageNoResultsContainer .catalogPageNoResultsBtnWrapper .catalogPageNoResultsButton .catalogPageNoResultsButtonText {
        font-style: normal;
        font-size: 16px;
        font-weight: 600; }
