DIV.termsAndConditionsOptions {
  margin-top: 25px;
  float: left; }
  DIV.termsAndConditionsOptions BUTTON {
    float: left;
    width: 200px;
    margin-right: 15px;
    font-size: 16px;
    padding: 0px 32px; }

.termsAndConditionsHeader {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: #101f47; }

DIV.termsAndConditionsWrapper {
  background-color: transparent; }

DIV.termsAndConditionsContainer {
  background-color: transparent; }

DIV.termsAndConditionsText {
  background-color: transparent;
  margin-top: 25px;
  margin-bottom: 10px; }
  DIV.termsAndConditionsText P {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #5c6170; }
