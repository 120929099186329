.globalSearchLoadMore {
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  border: 0.833333px solid #009fd1;
  box-sizing: border-box;
  border-radius: 6px;
  color: #009fd1;
  cursor: pointer; }

.buttonLoadingContainer {
  width: 35px !important;
  text-align: left;
  margin-right: 10px;
  margin-top: -11px; }

.buttonLoadingText {
  display: block;
  flex-grow: 1;
  font-size: 15px;
  height: 12px;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 10px;
  margin-top: 5px; }

.buttonLoadingContainerLoaderBlue {
  animation: pageSizeLoadingContainerAnimation 1.3s linear infinite;
  border-radius: 50%;
  color: #32c0f3;
  font-size: 3px;
  height: 6px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 9px;
  position: relative;
  text-indent: -9999px;
  transform: translateZ(0);
  width: 6px; }

.buttonLoadingContainerLoaderWhite {
  animation: pageSizeLoadingContainerAnimation 1.3s linear infinite;
  border-radius: 50%;
  color: #ffffff;
  font-size: 3px;
  height: 6px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 9px;
  position: relative;
  text-indent: -9999px;
  transform: translateZ(0);
  width: 6px; }

@keyframes pageSizeLoadingContainerAnimation {
  0%,
  to {
    box-shadow: 0 -9px 0 0.2px, 6px -6px 0 0, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 0; }
  12.5% {
    box-shadow: 0 -9px 0 0, 6px -6px 0 0.2px, 9px 0 0 0, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 -3px; }
  25% {
    box-shadow: 0 -9px 0 -0.5px, 6px -6px 0 0, 9px 0 0 0.2px, 6px 6px 0 0, 0 9px 0 -3px, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 -3px; }
  37.5% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 0, 6px 6px 0 0.2px, 0 9px 0 0, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 -3px; }
  50% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 0, 0 9px 0 0.2px, -6px 6px 0 0, -9px 0 0 -3px, -6px -6px 0 -3px; }
  62.5% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 0, -6px 6px 0 0.2px, -9px 0 0 0, -6px -6px 0 -3px; }
  75% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 0, -9px 0 0 0.2px, -6px -6px 0 0; }
  87.5% {
    box-shadow: 0 -9px 0 0, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 0, -9px 0 0 0, -6px -6px 0 0.2px; } }

.buttonLoadingContainerCount {
  margin-left: 8px; }

.buttonLoadMoreMetrics {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
