DIV.checkboxWrapper {
  display: flex;
  flex-direction: row; }

DIV.checkboxWrapper > DIV.checkboxStyleWrapper > INPUT[type='checkbox']:focus {
  outline: 1px solid rgba(0, 0, 0, 0.2); }

DIV.checkboxWrapper > DIV.checkboxStyleWrapper > INPUT[type='checkbox'] {
  background-color: #ffffff;
  border: 1px solid #b7bccb;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  top: 5px;
  float: left;
  margin-right: 7px;
  margin-top: -3px;
  margin-bottom: 8px;
  margin-left: 0px; }

DIV.checkboxWrapper > DIV.checkboxStyleWrapper > INPUT[type='checkbox']:checked {
  background-color: #009fd1;
  background: #009fd1 url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 6px 6px no-repeat;
  border: 1px solid #009fd1; }

.checkboxLabel {
  margin-top: 4px; }

.floatClear {
  clear: both; }
