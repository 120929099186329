.courseDetailsPageContentWrapper {
  width: 100%; }

.courseDetailTabWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 19px; }

.courseTitle {
  font-size: 38px;
  font-weight: 800;
  line-height: 38px; }
  @media (min-width: 1920px) {
    .courseTitle {
      margin: -135px 20px 100px 0px;
      color: #ffffff; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    .courseTitle {
      margin: -135px 20px 100px 0px;
      color: #ffffff; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    .courseTitle {
      margin: -135px 20px 100px 3px;
      color: #ffffff; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    .courseTitle {
      margin: -135px 20px 100px 3px;
      color: #ffffff; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    .courseTitle {
      margin: 285px 15px 25px 15px;
      color: #1d2332; } }
  @media (max-width: 359.99px) {
    .courseTitle {
      margin: 285px 15px 25px 15px;
      color: #1d2332; } }

DIV.courseImagePrimary > IMG {
  position: absolute;
  object-fit: cover; }
  @media (min-width: 1920px) {
    DIV.courseImagePrimary > IMG {
      top: 116px;
      left: 50px;
      width: 326px;
      height: 225px;
      border-radius: 6px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.courseImagePrimary > IMG {
      top: 116px;
      left: 50px;
      width: 326px;
      height: 225px;
      border-radius: 6px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV.courseImagePrimary > IMG {
      top: 116px;
      left: 50px;
      width: 230px;
      height: 174px;
      border-radius: 6px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV.courseImagePrimary > IMG {
      top: 116px;
      left: 50px;
      width: 230px;
      height: 174px;
      border-radius: 6px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.courseImagePrimary > IMG {
      top: 68px;
      left: calc((100vw - 326px) / 2);
      width: 326px;
      height: 326px;
      border-radius: 8px 8px 8px 8px; } }
  @media (max-width: 359.99px) {
    DIV.courseImagePrimary > IMG {
      top: 68px;
      left: calc((100vw - 312px) / 2);
      width: 312px;
      height: 312px;
      border-radius: 8px 8px 8px 8px; } }

/*
 .sessionHeaderDownloadCalendarLinkWrapper {
   background-color: dodgerblue;

   BUTTON.sessionHeaderDownloadCalendarLink {
     position: absolute;
     top: 344px;
     left: 90px;
     background: #009fd1;
     width: 226px;
     align-items: center;
     border: none;
     border-radius: 4px;
     color: #ffffff;
     cursor: pointer;
     font-family: $sans-serif;
     font-size: 16px;
     font-weight: 500;
     height: 46px;
     justify-content: center;
     line-height: 17px;
     outline: none;
     padding-top: 6px;
     text-align: center;
     text-decoration: none;
     transition: all 0.15s ease-in-out;

     DIV.sessionHeaderDownloadCalendarLinkContent {
       display: flex;
       flex-direction: row;
       justify-content: center;

       DIV.sessionHeaderDownloadCalendarLinkButtonImage {
         margin-bottom: 0px;
       }

       DIV.sessionHeaderDownloadCalendarLinkButtonText {
         margin-top: 5px;
         margin-left: 7px;
       }
     }
   }
 }
 */
