DIV.ProfileChangePasswordWrapper {
  width: 100%; }
  @media (min-width: 768px) {
    DIV.ProfileChangePasswordWrapper {
      margin-top: 25px; } }
  @media (max-width: 767.99px) {
    DIV.ProfileChangePasswordWrapper {
      margin-top: -25px; } }
  DIV.ProfileChangePasswordWrapper .sectionTitle {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px; }
  DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper {
    display: flex;
    flex-direction: row; }
    DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper DIV.profileChangePasswordSubmitFeedback {
      background-color: transparent;
      text-align: left;
      width: 100%; }
      DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper DIV.profileChangePasswordSubmitFeedback DIV.profileChangePasswordSubmitFeedbackPositive {
        color: #2c8fbf; }
      DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper DIV.profileChangePasswordSubmitFeedback DIV.profileChangePasswordSubmitFeedbackNegative {
        color: #e4333e; }
    DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper DIV.profileChangePasswordActionButtons {
      text-align: right;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      width: 100%; }
      DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper DIV.profileChangePasswordActionButtons .profileChangePasswordActionButtonsSignOut {
        text-align: left;
        justify-content: left;
        width: 50%; }
        DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper DIV.profileChangePasswordActionButtons .profileChangePasswordActionButtonsSignOut BUTTON {
          padding-left: 24px;
          min-width: 115px;
          width: 115px; }
      DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper DIV.profileChangePasswordActionButtons .profileChangePasswordActionButtonsChange {
        text-align: right;
        justify-content: right;
        width: 50%; }
        DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper DIV.profileChangePasswordActionButtons .profileChangePasswordActionButtonsChange BUTTON {
          padding-left: 24px;
          min-width: 185px; }
  DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordCurrent {
    background-color: transparent; }
  DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordNew {
    background-color: transparent; }
    DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordNew DIV.profileChangePasswordNewError {
      color: #e4333e;
      margin-top: -30px; }
    DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordNew DIV > DIV {
      margin-top: 20px; }
  DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordChallenge {
    background-color: transparent; }
    DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordChallenge DIV.profileChangePasswordInputError {
      color: #e4333e;
      margin-top: -30px; }
    DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordChallenge DIV > DIV {
      margin-top: 20px; }
  DIV.ProfileChangePasswordWrapper BUTTON.button-reverse-l {
    border: 1px solid #009fd1;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    outline: 1px dashed transparent;
    font-size: 16px;
    padding: 0px 32px 0px 32px;
    padding-right: 32px;
    width: inherit; }
  DIV.ProfileChangePasswordWrapper BUTTON.button-default-l {
    border: 1px solid transparent;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    outline: 1px dashed transparent;
    font-size: 16px;
    padding: 0px 32px 0px 32px;
    width: inherit; }
  DIV.ProfileChangePasswordWrapper DIV.AboutMeEditButtonWrapper {
    text-align: right; }
  DIV.ProfileChangePasswordWrapper DIV.profileChangePasswordSubmitWrapper {
    text-align: right; }
