INPUT.textInputComp {
  color: #1d2332;
  font-family: inherit;
  line-height: normal;
  outline: none;
  padding: 16px 27px 15px 26px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(77, 89, 120, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px; }

input[type='text']:disabled {
  opacity: 0.45; }

INPUT.textInputCompHalf {
  color: #1d2332;
  font-family: inherit;
  line-height: normal;
  outline: none;
  padding: 16px 27px 15px 26px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(77, 89, 120, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px; }

SPAN.requiredFieldIdentifier {
  color: red;
  font-weight: 800; }
