@charset "UTF-8";
/*
* Sass master
*/
/*
* Sass variables
*/
/*
* Sass mixins
*/
/*
* Sass colors
*/
/* theme colors */
/* fonts */
/* buttons */
/*
* Sass typography
*/
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Google+Sans:100,300,400,500,700,900,100i,300i,400i,500i,700i,900i");
/*
* Sass colors
*/
/* theme colors */
/* fonts */
/* buttons */
@font-face {
  font-family: 'Futura';
  src: local("Futura"), url(../assets/fonts/Futura/Futura-Medium.woff) format("woff"); }

@font-face {
  font-family: 'Gilroy';
  src: local("Gilroy"), url(../assets/fonts/Gilroy/Gilroy-Regular.woff) format("woff"); }

@font-face {
  font-family: 'Gilroy';
  font-weight: bold;
  src: local("Gilroy"), url(../assets/fonts/Gilroy/Gilroy-Black.woff) format("woff"); }

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  src: local("Gilroy"), url(../assets/fonts/Gilroy/Gilroy-BlackItalic.woff) format("woff"); }

@font-face {
  font-family: 'Gilroy';
  font-weight: bold;
  font-style: italic;
  src: local("Gilroy"), url(../assets/fonts/Gilroy/Gilroy-BoldItalic.woff) format("woff"); }

HTML,
BODY,
TABLE,
TBODY,
THEAD,
TH,
TR,
TD,
DIV,
P,
BLOCKQUOTE,
DL,
DT,
UL,
OL,
LI,
PRE,
DD {
  font-family: "Lexand Deca", "Google Sans", "Khula", "Open Sans", "Lato", Helvetica, Verdana, Arial, sans-serif; }

.fontDefaultExtraLight {
  font-family: "Lexand Deca";
  font-weight: 100;
  font-style: normal; }

.fontDefaultLight {
  font-family: "Lexand Deca";
  font-weight: 300;
  font-style: normal; }

.fontDefaultRegular {
  font-family: "Lexand Deca";
  font-weight: 400;
  font-style: normal; }

.fontDefaultBold {
  font-family: "Lexand Deca";
  font-weight: 700;
  font-style: normal; }

.fontDefaultExtraBold {
  font-family: "Lexand Deca";
  font-weight: 900;
  font-style: normal; }

.fontDefaultExtraLightItalic {
  font-family: "Lexand Deca";
  font-weight: 100;
  font-style: italic; }

.fontDefaultLightItalic {
  font-family: "Lexand Deca";
  font-weight: 300;
  font-style: italic; }

.fontDefaultRegularItalic {
  font-family: "Lexand Deca";
  font-weight: 400;
  font-style: italic; }

.fontDefaultBoldItalic {
  font-family: "Lexand Deca";
  font-weight: 700;
  font-style: italic; }

.fontDefaultExtraBoldItalic {
  font-family: "Lexand Deca";
  font-weight: 900;
  font-style: italic; }

.fontPrimaryExtraLight {
  font-family: "Google Sans";
  font-weight: 100;
  font-style: normal; }

.fontPrimaryLight {
  font-family: "Google Sans";
  font-weight: 300;
  font-style: normal; }

.fontPrimaryRegular {
  font-family: "Google Sans";
  font-weight: 400;
  font-style: normal; }

.fontPrimaryBold {
  font-family: "Google Sans";
  font-weight: 700;
  font-style: normal; }

.fontPrimaryExtraBold {
  font-family: "Google Sans";
  font-weight: 900;
  font-style: normal; }

.fontPrimaryExtraLightItalic {
  font-family: "Google Sans";
  font-weight: 100;
  font-style: italic; }

.fontPrimaryLightItalic {
  font-family: "Google Sans";
  font-weight: 300;
  font-style: italic; }

.fontPrimaryRegularItalic {
  font-family: "Google Sans";
  font-weight: 400;
  font-style: italic; }

.fontPrimaryBoldItalic {
  font-family: "Google Sans";
  font-weight: 700;
  font-style: italic; }

.fontPrimaryExtraBoldItalic {
  font-family: "Google Sans";
  font-weight: 900;
  font-style: italic; }

.fontSecondaryExtraLight {
  font-family: "Open Sans";
  font-weight: 100;
  font-style: normal; }

.fontSecondaryLight {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: normal; }

.fontSecondaryRegular {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal; }

.fontSecondaryBold {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: normal; }

.fontSecondaryExtraBold {
  font-family: "Open Sans";
  font-weight: 900;
  font-style: normal; }

.fontSecondaryExtraLightItalic {
  font-family: "Open Sans";
  font-weight: 100;
  font-style: italic; }

.fontSecondaryLightItalic {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: italic; }

.fontSecondaryRegularItalic {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: italic; }

.fontSecondaryBoldItalic {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: italic; }

.fontSecondaryExtraBoldItalic {
  font-family: "Open Sans";
  font-weight: 900;
  font-style: italic; }

.fontTertiaryExtraLight {
  font-family: "Khula";
  font-weight: 100;
  font-style: normal; }

.fontTertiaryLight {
  font-family: "Khula";
  font-weight: 300;
  font-style: normal; }

.fontTertiaryRegular {
  font-family: "Khula";
  font-weight: 400;
  font-style: normal; }

.fontTertiaryBold {
  font-family: "Khula";
  font-weight: 700;
  font-style: normal; }

.fontTertiaryExtraBold {
  font-family: "Khula";
  font-weight: 900;
  font-style: normal; }

.fontTertiaryExtraLightItalic {
  font-family: "Khula";
  font-weight: 100;
  font-style: italic; }

.fontTertiaryLightItalic {
  font-family: "Khula";
  font-weight: 300;
  font-style: italic; }

.fontTertiaryRegularItalic {
  font-family: "Khula";
  font-weight: 400;
  font-style: italic; }

.fontTertiaryBoldItalic {
  font-family: "Khula";
  font-weight: 700;
  font-style: italic; }

.fontTertiaryExtraBoldItalic {
  font-family: "Khula";
  font-weight: 900;
  font-style: italic; }

.horizontalLinkListBlue {
  justify-content: center;
  text-align: center; }

.horizontalLinkListBlue > a,
.horizontalLinkListBlue > a:visited {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #009fd1;
  text-decoration: none;
  margin-right: 45px;
  justify-content: center;
  text-align: center; }

.horizontalLinkListBlue > a:hover {
  color: #00cbe9;
  text-decoration: underline; }

.horizontalLinkListBlue > a:last-child {
  margin-right: 0px; }

/*
* Sass imagery
*/
/* Style mocks */
.paragraphIntroTitle {
  background-color: #1d2332;
  color: #ffffff;
  padding: 6px;
  border: #00cbe9; }

.sectionIntroTitle {
  color: #009fd1;
  font-weight: 800;
  padding: 16px; }

.mocksWrapper {
  display: flex;
  gap: 12px; }

.mocksDiv {
  flex: 1;
  border-radius: 25px;
  border: 3px solid #009fd1;
  padding: 10px; }

#fontsPrimary,
#fontsSecondary,
#fontsTertiary {
  flex: 2; }

/* remove in prod */
._bodyContainer {
  margin: 0px;
  padding: 0px; }

.basicDiv {
  padding: 20px; }

.noShow {
  visibility: hidden; }

.errorMessageWrapper {
  margin: 10px 0px 10px 21px; }

.errorMessage {
  font-weight: 400;
  font-size: 14pt; }

.subErrorMessage {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14pt; }

.hiddenElement {
  visibility: hidden;
  background-color: transparent;
  width: 0px;
  height: 0px; }

/* page tab headers */
.tabHeaderContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px; }

.tabHeaderContainerLink {
  text-align: right;
  width: 100%; }

.tabHeaderContainerLinkButtonEnabled {
  background: #009fd1;
  border: none;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 8px 24px 10px 24px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  margin-top: 19px;
  cursor: pointer;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  width: 275px;
  height: 40px; }

.tabHeaderContainerLinkButtonDisabled {
  background-color: #94d4eb;
  border: none;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 8px 24px 10px 24px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  margin-top: 19px;
  cursor: not-allowed;
  width: 275px;
  height: 40px; }

.loadMoreButton {
  background: #009fd1;
  border: none;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px 10px 24px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  margin-top: 19px;
  cursor: pointer;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif; }

.tabItem {
  border: none;
  margin-top: 20px;
  background-color: transparent; }
  .tabItem BUTTON {
    border: none;
    font-weight: 700;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #101f47;
    margin-bottom: 25px;
    margin-right: 25px;
    cursor: pointer;
    opacity: 0.5;
    background-color: transparent; }
    .tabItem BUTTON SPAN:hover {
      color: #000000;
      opacity: 1; }
    .tabItem BUTTON DIV.lightBlueHalfUnderline {
      background: transparent;
      border-radius: 24px;
      height: 4px;
      width: 49px;
      margin-top: 7px;
      line-height: 4px; }

.tabItemActive {
  background-color: transparent; }
  .tabItemActive BUTTON {
    border: none;
    font-weight: 700;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #101f47;
    margin-bottom: 25px;
    margin-right: 25px;
    cursor: pointer;
    opacity: 1;
    background-color: transparent; }
    .tabItemActive BUTTON SPAN:hover {
      opacity: 1; }
    .tabItemActive BUTTON DIV.lightBlueHalfUnderline {
      background: #00cbe9;
      border-radius: 24px;
      height: 4px;
      width: 49px;
      margin-top: 7px;
      line-height: 4px; }

.shareThisWrapper {
  background-color: transparent;
  position: absolute;
  display: flex;
  flex-direction: row; }
  @media (min-width: 1920px) {
    .shareThisWrapper {
      top: 437px;
      left: 50px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    .shareThisWrapper {
      top: 437px;
      left: 50px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    .shareThisWrapper {
      top: 372px;
      left: 50px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    .shareThisWrapper {
      top: 372px;
      left: 50px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    .shareThisWrapper {
      top: 466px;
      left: calc((100vw - 326px) / 2); } }
  @media (max-width: 359.99px) {
    .shareThisWrapper {
      top: 466px;
      left: calc((100vw - 326px) / 2); } }
  .shareThisWrapper DIV.MuiPopover-paper {
    border-radius: 8px;
    width: 330px;
    height: 57px;
    margin-left: 0px;
    margin-top: 0px;
    font-family: 'Lexand Deca', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px; }

.shareThisButtonIcon {
  margin: 2px 13px 0px 6px; }

.shareThisButtonText {
  border: none;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  margin-top: 0px;
  margin-left: 0px; }

.shareThisButtonClose {
  background-color: #009fd1;
  color: #ffffff;
  border-radius: 52px;
  margin-left: 10px;
  border: transparent;
  padding: 3px 10px 4px 10px;
  cursor: pointer; }

.shareThisButtonClose:hover {
  background: #009fd133;
  color: #000000; }

.shareThisButtonContentWrapper {
  display: flex;
  flex-direction: row;
  width: 88%;
  padding: 18px; }

.shareThisButtonContentText {
  border: none;
  margin: auto auto auto 0px;
  font-size: 15px;
  padding-top: 3px; }

.shareThisButtonContentAction {
  border: none;
  text-align: right;
  justify-content: flex-end;
  margin: auto 0px auto auto; }

DIV#shareThis > DIV.MuiPaper-root {
  border-radius: 8px;
  width: 330px;
  margin-left: 0px;
  margin-top: 0px;
  font-family: 'Lexand Deca', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px; }
  @media (min-width: 1920px) {
    DIV#shareThis > DIV.MuiPaper-root {
      width: 330px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV#shareThis > DIV.MuiPaper-root {
      width: 330px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV#shareThis > DIV.MuiPaper-root {
      width: 230px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV#shareThis > DIV.MuiPaper-root {
      width: 230px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV#shareThis > DIV.MuiPaper-root {
      width: 326px; } }
  @media (max-width: 359.99px) {
    DIV#shareThis > DIV.MuiPaper-root {
      width: 326px; } }

.inputErrorTextBox {
  color: #e4333e;
  font-size: 16px;
  line-height: 15px; }

.formSubmitError {
  color: #e4333e;
  font-size: 16px;
  line-height: 15px; }

.inputFieldInvalidText {
  color: #e4333e; }

#passwordInputError,
#emailInputError {
  text-align: left;
  margin: -40px 0px 0px 25px;
  font-size: 16px; }

.formDiv {
  margin-bottom: 25px; }

.formText {
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-size: 16px;
  line-height: 15px;
  color: #1d2332;
  text-align: left;
  margin: 30px 0px 8px 25px;
  justify-content: left; }

.formSubmitError {
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-size: 16px;
  color: #e4333e;
  margin-bottom: 25px;
  text-align: left;
  justify-content: left;
  margin-left: 25px;
  line-height: 22px; }

.inputTextBoxValid {
  background: #ffffff;
  border: 1px solid silver;
  box-sizing: border-box;
  border-radius: 6px;
  height: 44px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-bottom: 20px; }
  @media (min-width: 1101px) {
    .inputTextBoxValid {
      width: 408px; } }
  @media (min-width: 601px) and (max-width: 1100px) {
    .inputTextBoxValid {
      width: 408px; } }
  @media (max-width: 600px) {
    .inputTextBoxValid {
      width: 100%;
      max-width: 375px; } }

.inputTextBoxInvalid {
  background: #ffffff;
  border: 1px solid #e4333e;
  box-sizing: border-box;
  border-radius: 6px;
  height: 44px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-bottom: 20px; }
  @media (min-width: 1101px) {
    .inputTextBoxInvalid {
      width: 408px; } }
  @media (min-width: 601px) and (max-width: 1100px) {
    .inputTextBoxInvalid {
      width: 408px; } }
  @media (max-width: 600px) {
    .inputTextBoxInvalid {
      width: 100%;
      max-width: 375px; } }

/*
* Sass components master
*/
/*
/*
* Sass buttons

* $style = default, strong, reverse, soft
* $size  = s, m, l, xl
*/
.button-default-s {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #009fd1;
  border-color: #009fd1;
  padding: 8px 16px;
  width: 90px;
  height: 32px; }
  .button-default-s:hover {
    background-color: #2c8fbf; }
  .button-default-s:active {
    background-color: #009fd1; }
  .button-default-s:disabled {
    background-color: #94d4eb; }
  .button-default-s label {
    font-size: 14px;
    margin: 0px 7px; }
  .button-default-s label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-default-m {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #009fd1;
  border-color: #009fd1;
  padding: 10px 24px;
  width: 111px;
  height: 40px; }
  .button-default-m:hover {
    background-color: #2c8fbf; }
  .button-default-m:active {
    background-color: #009fd1; }
  .button-default-m:disabled {
    background-color: #94d4eb; }
  .button-default-m label {
    font-size: 16px;
    margin: 0px 8px; }
  .button-default-m label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-default-l {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #009fd1;
  border-color: #009fd1;
  padding: 16px 32px;
  width: 127px;
  height: 48px; }
  .button-default-l:hover {
    background-color: #2c8fbf; }
  .button-default-l:active {
    background-color: #009fd1; }
  .button-default-l:disabled {
    background-color: #94d4eb; }
  .button-default-l label {
    font-size: 16px;
    margin: 0px 8px; }
  .button-default-l label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-default-xl {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #009fd1;
  border-color: #009fd1;
  width: 151px;
  height: 56px; }
  .button-default-xl:hover {
    background-color: #2c8fbf; }
  .button-default-xl:active {
    background-color: #009fd1; }
  .button-default-xl:disabled {
    background-color: #94d4eb; }
  .button-default-xl label {
    font-size: 16px;
    margin: 0px 7px; }
  .button-default-xl label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-strong-s {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #0084ad;
  border-color: #0084ad;
  padding: 8px 16px;
  width: 90px;
  height: 32px; }
  .button-strong-s:hover {
    background-color: #226e91; }
  .button-strong-s:active {
    background-color: #0084ad; }
  .button-strong-s:disabled {
    background-color: #94d4eb; }
  .button-strong-s label {
    font-size: 14px;
    margin: 0px 7px; }
  .button-strong-s label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-strong-m {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #0084ad;
  border-color: #0084ad;
  padding: 10px 24px;
  width: 111px;
  height: 40px; }
  .button-strong-m:hover {
    background-color: #226e91; }
  .button-strong-m:active {
    background-color: #0084ad; }
  .button-strong-m:disabled {
    background-color: #94d4eb; }
  .button-strong-m label {
    font-size: 16px;
    margin: 0px 8px; }
  .button-strong-m label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-strong-l {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #0084ad;
  border-color: #0084ad;
  padding: 16px 32px;
  width: 127px;
  height: 48px; }
  .button-strong-l:hover {
    background-color: #226e91; }
  .button-strong-l:active {
    background-color: #0084ad; }
  .button-strong-l:disabled {
    background-color: #94d4eb; }
  .button-strong-l label {
    font-size: 16px;
    margin: 0px 8px; }
  .button-strong-l label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-strong-xl {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #0084ad;
  border-color: #0084ad;
  width: 151px;
  height: 56px; }
  .button-strong-xl:hover {
    background-color: #226e91; }
  .button-strong-xl:active {
    background-color: #0084ad; }
  .button-strong-xl:disabled {
    background-color: #94d4eb; }
  .button-strong-xl label {
    font-size: 16px;
    margin: 0px 7px; }
  .button-strong-xl label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-reverse-s {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #009fd1;
  background-color: #f6f8fc;
  border-color: #009fd1;
  border: 3px solid #009fd1;
  padding: 8px 16px;
  width: 90px;
  height: 32px; }
  .button-reverse-s:hover {
    background-color: #f6f8fc; }
  .button-reverse-s:active {
    background-color: #f6f8fc; }
  .button-reverse-s:disabled {
    background-color: #94d4eb; }
  .button-reverse-s label {
    font-size: 14px;
    margin: 0px 7px; }
  .button-reverse-s label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #009fd1; }

.button-reverse-m {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #009fd1;
  background-color: #f6f8fc;
  border-color: #009fd1;
  border: 3px solid #009fd1;
  padding: 10px 24px;
  width: 111px;
  height: 40px; }
  .button-reverse-m:hover {
    background-color: #f6f8fc; }
  .button-reverse-m:active {
    background-color: #f6f8fc; }
  .button-reverse-m:disabled {
    background-color: #94d4eb; }
  .button-reverse-m label {
    font-size: 16px;
    margin: 0px 8px; }
  .button-reverse-m label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #009fd1; }

.button-reverse-l {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #009fd1;
  background-color: #f6f8fc;
  border-color: #009fd1;
  border: 3px solid #009fd1;
  padding: 16px 32px;
  width: 127px;
  height: 48px; }
  .button-reverse-l:hover {
    background-color: #f6f8fc; }
  .button-reverse-l:active {
    background-color: #f6f8fc; }
  .button-reverse-l:disabled {
    background-color: #94d4eb; }
  .button-reverse-l label {
    font-size: 16px;
    margin: 0px 8px; }
  .button-reverse-l label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #009fd1; }

.button-reverse-xl {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #009fd1;
  background-color: #f6f8fc;
  border-color: #009fd1;
  border: 3px solid #009fd1;
  width: 151px;
  height: 56px; }
  .button-reverse-xl:hover {
    background-color: #f6f8fc; }
  .button-reverse-xl:active {
    background-color: #f6f8fc; }
  .button-reverse-xl:disabled {
    background-color: #94d4eb; }
  .button-reverse-xl label {
    font-size: 16px;
    margin: 0px 7px; }
  .button-reverse-xl label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #009fd1; }

.button-soft-s {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #94d4eb;
  border-color: #94d4eb;
  padding: 8px 16px;
  width: 90px;
  height: 32px; }
  .button-soft-s:hover {
    background-color: #a5cee4; }
  .button-soft-s:active {
    background-color: #94d4eb; }
  .button-soft-s:disabled {
    background-color: #f6f8fc; }
  .button-soft-s label {
    font-size: 14px;
    margin: 0px 7px; }
  .button-soft-s label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-soft-m {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #94d4eb;
  border-color: #94d4eb;
  padding: 10px 24px;
  width: 111px;
  height: 40px; }
  .button-soft-m:hover {
    background-color: #a5cee4; }
  .button-soft-m:active {
    background-color: #94d4eb; }
  .button-soft-m:disabled {
    background-color: #f6f8fc; }
  .button-soft-m label {
    font-size: 16px;
    margin: 0px 8px; }
  .button-soft-m label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-soft-l {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #94d4eb;
  border-color: #94d4eb;
  padding: 16px 32px;
  width: 127px;
  height: 48px; }
  .button-soft-l:hover {
    background-color: #a5cee4; }
  .button-soft-l:active {
    background-color: #94d4eb; }
  .button-soft-l:disabled {
    background-color: #f6f8fc; }
  .button-soft-l label {
    font-size: 16px;
    margin: 0px 8px; }
  .button-soft-l label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.button-soft-xl {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #94d4eb;
  border-color: #94d4eb;
  width: 151px;
  height: 56px; }
  .button-soft-xl:hover {
    background-color: #a5cee4; }
  .button-soft-xl:active {
    background-color: #94d4eb; }
  .button-soft-xl:disabled {
    background-color: #f6f8fc; }
  .button-soft-xl label {
    font-size: 16px;
    margin: 0px 7px; }
  .button-soft-xl label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.spinnerContainer {
  position: relative;
  display: inline-table;
  height: 6px;
  width: 6px; }

.spinnerLoader {
  animation: buttonAnimation 1.3s linear infinite;
  border-radius: 50%;
  color: #fff;
  height: 6px;
  width: 6px; }

.spinnerLoaderInverted {
  animation: buttonAnimation 1.3s linear infinite;
  border-radius: 50%;
  color: #009fd1;
  height: 6px;
  width: 6px; }

@keyframes buttonAnimation {
  0%,
  to {
    box-shadow: 0 -9px 0 0.2px, 6px -6px 0 0, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 0; }
  12.5% {
    box-shadow: 0 -9px 0 0, 6px -6px 0 0.2px, 9px 0 0 0, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 -3px; }
  25% {
    box-shadow: 0 -9px 0 -0.5px, 6px -6px 0 0, 9px 0 0 0.2px, 6px 6px 0 0, 0 9px 0 -3px, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 -3px; }
  37.5% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 0, 6px 6px 0 0.2px, 0 9px 0 0, -6px 6px 0 -3px, -9px 0 0 -3px, -6px -6px 0 -3px; }
  50% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 0, 0 9px 0 0.2px, -6px 6px 0 0, -9px 0 0 -3px, -6px -6px 0 -3px; }
  62.5% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 0, -6px 6px 0 0.2px, -9px 0 0 0, -6px -6px 0 -3px; }
  75% {
    box-shadow: 0 -9px 0 -3px, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 0, -9px 0 0 0.2px, -6px -6px 0 0; }
  87.5% {
    box-shadow: 0 -9px 0 0, 6px -6px 0 -3px, 9px 0 0 -3px, 6px 6px 0 -3px, 0 9px 0 -3px, -6px 6px 0 0, -9px 0 0 0, -6px -6px 0 0.2px; } }

.buttonWideBlue {
  align-items: center;
  background: #009fd1;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  line-height: 17px;
  outline: none;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  width: 406px; }

.buttonWideBlue:hover {
  background-color: #00cbe9; }

.buttonWideBlue:focus {
  box-shadow: 0 0 0 2px rgba(179, 179, 179, 0.5); }

.buttonWideBlue[disabled] {
  background-color: #94d4eb;
  cursor: not-allowed; }

.buttonWideBlueWait {
  align-items: center;
  background: #94d4eb;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  line-height: 17px;
  outline: none;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  width: 406px; }

.buttonRegularBlue {
  align-items: center;
  background: #009fd1;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  line-height: 17px;
  outline: none;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  width: 250px; }

.buttonRegularBlue:hover {
  background-color: #00cbe9; }

.buttonRegularBlue:focus {
  box-shadow: 0 0 0 2px rgba(179, 179, 179, 0.5); }

.buttonRegularBlue[disabled] {
  background-color: #94d4eb;
  cursor: not-allowed; }

.buttonRegularBlueWait {
  align-items: center;
  background: #94d4eb;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  line-height: 17px;
  outline: none;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  width: 250px; }

.buttonModalDefault {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: #009fd1;
  border-color: #009fd1;
  width: 151px;
  height: 56px;
  border: 1px solid #009fd1;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  padding: 0px 50px 0px 50px;
  width: 215px !important; }
  .buttonModalDefault:hover {
    background-color: #2c8fbf; }
  .buttonModalDefault:active {
    background-color: #009fd1; }
  .buttonModalDefault:disabled {
    background-color: #94d4eb; }
  .buttonModalDefault label {
    font-size: 16px;
    margin: 0px 7px; }
  .buttonModalDefault label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #ffffff; }

.buttonModalReverse {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #009fd1;
  background-color: #f6f8fc;
  border-color: #009fd1;
  border: 3px solid #009fd1;
  width: 151px;
  height: 56px;
  border: 1px solid #009fd1;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  background-color: #ffffff;
  padding: 0px 50px 0px 50x;
  width: 215px !important; }
  .buttonModalReverse:hover {
    background-color: #f6f8fc; }
  .buttonModalReverse:active {
    background-color: #f6f8fc; }
  .buttonModalReverse:disabled {
    background-color: #94d4eb; }
  .buttonModalReverse label {
    font-size: 16px;
    margin: 0px 7px; }
  .buttonModalReverse label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #009fd1; }

.shareThisButton {
  align-items: center;
  border-radius: 6px;
  margin: 4px;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #009fd1;
  background-color: #f6f8fc;
  border-color: #009fd1;
  border: 3px solid #009fd1;
  width: 151px;
  height: 56px;
  align-items: left;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #009fd1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-weight: 500;
  justify-content: left;
  line-height: 17px;
  margin: 0px 0px 10px 0px !important;
  padding: 0px 50px 0px 50x;
  text-align: left;
  text-decoration: none; }
  .shareThisButton:hover {
    background-color: #f6f8fc; }
  .shareThisButton:active {
    background-color: #f6f8fc; }
  .shareThisButton:disabled {
    background-color: #94d4eb; }
  .shareThisButton label {
    font-size: 16px;
    margin: 0px 7px; }
  .shareThisButton label {
    position: static;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #009fd1; }
  @media (min-width: 1920px) {
    .shareThisButton {
      width: 326px !important; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    .shareThisButton {
      width: 326px !important; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    .shareThisButton {
      width: 230px !important; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    .shareThisButton {
      width: 230px !important; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    .shareThisButton {
      width: 326px !important; } }
  @media (max-width: 359.99px) {
    .shareThisButton {
      width: 326px !important; } }

.circularProgressBackground {
  color: #e4f9fc; }

.circularProgress {
  color: #00cae9;
  animation-duration: 1500ms;
  margin-top: -100px; }

.circularProgressGlow {
  color: #37cbec;
  opacity: 0.8;
  filter: drop-shadow(-6px 6px 15px #37cbec88); }

/*
* Sass header
*/
.E1_header {
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100vw;
  color: white;
  display: flex;
  margin: 0px;
  padding: 0px;
  height: 88px;
  background-image: url("../assets/images/global/header/headerBackground.webp");
  background-repeat: no-repeat;
  background-color: #1d2332;
  /* misc */ }
  .E1_header A.profilePhotoLink,
  .E1_header A.profilePhotoLink:hover {
    text-decoration: none; }
  .E1_header .header_left {
    display: flex;
    flex: 1; }
    .E1_header .header_left .headerLogo {
      float: left;
      min-width: 160px;
      margin-top: 32px;
      margin-left: 35px; }
    .E1_header .header_left .headerLogoBranding {
      float: left;
      margin-right: 8px;
      margin-top: 1px; }
    .E1_header .header_left .headerLogoMark a,
    .E1_header .header_left .headerLogoMark a:visited {
      color: #ffffff;
      font-family: Futura;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2em;
      float: left;
      text-decoration: none;
      margin-top: 2px; }
    .E1_header .header_left .headerLogoBrandingClear {
      clear: both; }
    .E1_header .header_left .headerPrimaryNavigation {
      margin-left: 40px;
      margin-top: 37px;
      min-width: 300px; }
    .E1_header .header_left .headerNavLink,
    .E1_header .header_left .headerNavLink:visited {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      padding: 20px;
      color: #ffffff;
      font-family: "Lexand Deca";
      text-decoration: none; }
    .E1_header .header_left .headerNavLinkDropdown,
    .E1_header .header_left .headerNavLinkDropdown:visited {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      padding: 20px 5px 20px 20px;
      color: #ffffff;
      font-family: "Lexand Deca";
      text-decoration: none; }
    .E1_header .header_left DIV#headerPrimaryNavigation {
      background-color: transparent !important;
      margin-left: 40px;
      margin-top: 18px;
      min-width: 100px;
      display: flex;
      flex-direction: row;
      margin-right: 40px; }
      .E1_header .header_left DIV#headerPrimaryNavigation A.headerPrimaryNavigationMenuItem,
      .E1_header .header_left DIV#headerPrimaryNavigation A.headerPrimaryNavigationMenuItem:visited {
        color: #ffffff;
        white-space: nowrap;
        font-weight: 600;
        font-size: 16px;
        padding: 17px;
        cursor: pointer;
        text-decoration: none;
        font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif; }
      .E1_header .header_left DIV#headerPrimaryNavigation A.headerPrimaryNavigationMenuItem:hover {
        color: #ffffff;
        -webkit-text-stroke-width: 0.4px;
        -webkit-text-stroke-color: white; }
  .E1_header .header_left_tablet {
    display: flex;
    flex: 1; }
    @media (min-width: 1024px) and (max-width: 1365.99px) {
      .E1_header .header_left_tablet {
        width: 410px; } }
    @media (min-width: 768px) and (max-width: 1023.99px) {
      .E1_header .header_left_tablet {
        width: 290px; } }
    .E1_header .header_left_tablet .headerLogo {
      display: flex;
      flex-direction: row;
      margin-top: 32px;
      margin-left: 35px; }
      @media (max-width: 767.99px) {
        .E1_header .header_left_tablet .headerLogo {
          width: 43px; } }
      .E1_header .header_left_tablet .headerLogo .headerLogoBranding {
        margin-top: 2px;
        margin-right: 8px; }
      @media (min-width: 1024px) and (max-width: 1365.99px) {
        .E1_header .header_left_tablet .headerLogo .headerLogoMark {
          width: 125px; } }
      @media (min-width: 768px) and (max-width: 1023.99px) {
        .E1_header .header_left_tablet .headerLogo .headerLogoMark {
          width: 0px; } }
      .E1_header .header_left_tablet .headerLogo .headerLogoMark a,
      .E1_header .header_left_tablet .headerLogo .headerLogoMark a:visited {
        color: #ffffff;
        font-family: Futura;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.2em;
        float: left;
        text-decoration: none;
        margin-top: 2px; }
        @media (min-width: 1024px) and (max-width: 1365.99px) {
          .E1_header .header_left_tablet .headerLogo .headerLogoMark a,
          .E1_header .header_left_tablet .headerLogo .headerLogoMark a:visited {
            visibility: visible; } }
        @media (min-width: 768px) and (max-width: 1023.99px) {
          .E1_header .header_left_tablet .headerLogo .headerLogoMark a,
          .E1_header .header_left_tablet .headerLogo .headerLogoMark a:visited {
            visibility: hidden; } }
      .E1_header .header_left_tablet .headerLogo .headerLogoBrandingClear {
        clear: both; }
    .E1_header .header_left_tablet .headerPrimaryNavigation {
      margin-left: 40px;
      margin-top: 37px;
      min-width: 300px; }
    .E1_header .header_left_tablet .headerNavLink,
    .E1_header .header_left_tablet .headerNavLink:visited {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      padding: 20px;
      color: #ffffff;
      font-family: "Lexand Deca";
      text-decoration: none; }
    .E1_header .header_left_tablet .headerNavLinkDropdown,
    .E1_header .header_left_tablet .headerNavLinkDropdown:visited {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      padding: 20px 5px 20px 20px;
      color: #ffffff;
      font-family: "Lexand Deca";
      text-decoration: none; }
    .E1_header .header_left_tablet DIV#headerPrimaryNavigation {
      background-color: transparent !important;
      margin-top: 18px;
      min-width: 100px;
      display: flex;
      flex-direction: row;
      margin-right: 40px; }
      .E1_header .header_left_tablet DIV#headerPrimaryNavigation A.headerPrimaryNavigationMenuItem,
      .E1_header .header_left_tablet DIV#headerPrimaryNavigation A.headerPrimaryNavigationMenuItem:visited {
        color: #ffffff;
        white-space: nowrap;
        font-weight: 600;
        font-size: 16px;
        padding: 17px;
        cursor: pointer;
        text-decoration: none;
        font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif;
        color: #ffffff; }
      .E1_header .header_left_tablet DIV#headerPrimaryNavigation A.headerPrimaryNavigationMenuItem:hover {
        color: #ffffff;
        -webkit-text-stroke-width: 0.4px;
        -webkit-text-stroke-color: white; }
  .E1_header .header_left_mobile {
    display: flex;
    flex: 1;
    max-width: 58px; }
    .E1_header .header_left_mobile .headerLogo {
      float: left;
      margin-top: 20px;
      margin-left: 20px;
      width: 40px; }
    .E1_header .header_left_mobile .headerLogoBranding {
      float: left;
      margin-right: 8px; }
    .E1_header .header_left_mobile .headerLogoMark a,
    .E1_header .header_left_mobile .headerLogoMark a:visited {
      color: #ffffff;
      font-family: Futura;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2em;
      float: left;
      text-decoration: none;
      margin-top: 2px; }
      @media (min-width: 768px) and (max-width: 1365.99px) {
        .E1_header .header_left_mobile .headerLogoMark a,
        .E1_header .header_left_mobile .headerLogoMark a:visited {
          visibility: hidden; } }
      @media (max-width: 767.99px) {
        .E1_header .header_left_mobile .headerLogoMark a,
        .E1_header .header_left_mobile .headerLogoMark a:visited {
          visibility: hidden; } }
    .E1_header .header_left_mobile .headerLogoBrandingClear {
      clear: both; }
    .E1_header .header_left_mobile .headerPrimaryNavigation {
      margin-left: 40px;
      margin-top: 37px;
      min-width: 300px; }
    .E1_header .header_left_mobile .headerNavLink,
    .E1_header .header_left_mobile .headerNavLink:visited {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      padding: 20px;
      color: #ffffff;
      font-family: "Lexand Deca";
      text-decoration: none; }
    .E1_header .header_left_mobile .headerNavLinkDropdown,
    .E1_header .header_left_mobile .headerNavLinkDropdown:visited {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      padding: 20px 5px 20px 20px;
      color: #ffffff;
      font-family: "Lexand Deca";
      text-decoration: none; }
    .E1_header .header_left_mobile DIV#headerPrimaryNavigation {
      background-color: transparent !important;
      margin-left: 40px;
      margin-top: 18px;
      min-width: 100px;
      display: flex;
      flex-direction: row;
      margin-right: 40px; }
      .E1_header .header_left_mobile DIV#headerPrimaryNavigation A.headerPrimaryNavigationMenuItem,
      .E1_header .header_left_mobile DIV#headerPrimaryNavigation A.headerPrimaryNavigationMenuItem:visited {
        color: #ffffff;
        white-space: nowrap;
        font-weight: 600;
        font-size: 16px;
        padding: 17px;
        cursor: pointer;
        text-decoration: none;
        font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif;
        color: #ffffff; }
      .E1_header .header_left_mobile DIV#headerPrimaryNavigation A.headerPrimaryNavigationMenuItem:hover {
        color: #ffffff;
        -webkit-text-stroke-width: 0.4px;
        -webkit-text-stroke-color: white; }
  .E1_header .header_center {
    display: flex;
    flex: 2; }
    .E1_header .header_center .headerSearchInput {
      padding: 30px;
      color: #ffffff;
      font-family: "Lexand Deca";
      margin-left: 70px;
      margin-right: 70px;
      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
      min-width: 150px;
      max-width: 633px; }
      .E1_header .header_center .headerSearchInput .searchInputField {
        background: url("../assets/images/global/header/headerSearchMagnifyingGlass.svg") no-repeat, #151b2b !important;
        box-shadow: 5px 4px 14px #2a2e37;
        border-radius: 64px;
        border: none;
        align-items: flex-start;
        margin: -10px;
        padding: 0px 40px;
        margin-top: -10px;
        color: #858994;
        font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif !important;
        font-weight: 500;
        font-size: 12pt;
        text-align: left;
        width: 100%;
        height: 43px;
        outline: none;
        background-position: bottom 14px left 14px !important; }
      .E1_header .header_center .headerSearchInput .searchInputField:focus,
      .E1_header .header_center .headerSearchInput .searchInputField:hover {
        background: url("../assets/images/global/header/headerSearchMagnifyingGlass.svg") no-repeat, #000000 !important;
        border: none;
        border-radius: 64px;
        outline: none;
        color: #ffffff;
        font-weight: 500;
        background-position: bottom 14px left 14px !important; }
  .E1_header .header_center_tablet {
    display: flex;
    flex: 2;
    text-align: center; }
    .E1_header .header_center_tablet .headerSearchInput {
      padding: 30px;
      color: #ffffff;
      font-family: "Lexand Deca";
      text-align: center;
      margin-right: 70px;
      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
      min-width: 150px;
      max-width: 633px;
      justify-content: center; }
      @media (min-width: 1024px) and (max-width: 1365.99px) {
        .E1_header .header_center_tablet .headerSearchInput {
          margin-left: 10px; } }
      @media (min-width: 768px) and (max-width: 1023.99px) {
        .E1_header .header_center_tablet .headerSearchInput {
          margin-left: 0px; } }
      .E1_header .header_center_tablet .headerSearchInput .searchInputField {
        background: url("../assets/images/global/header/headerSearchMagnifyingGlass.svg") no-repeat, #151b2b !important;
        box-shadow: 5px 4px 14px #2a2e37;
        border-radius: 64px;
        border: none;
        align-items: flex-start;
        margin: -10px;
        padding: 0px 40px;
        margin-top: -10px;
        color: #858994;
        font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif !important;
        font-weight: 500;
        font-size: 12pt;
        text-align: left;
        width: 100%;
        height: 43px;
        outline: none;
        background-position: bottom 14px left 14px !important; }
      .E1_header .header_center_tablet .headerSearchInput .searchInputField:focus,
      .E1_header .header_center_tablet .headerSearchInput .searchInputField:hover {
        background: url("../assets/images/global/header/headerSearchMagnifyingGlass.svg") no-repeat, #000000 !important;
        border: none;
        border-radius: 64px;
        outline: none;
        color: #ffffff;
        font-weight: 500;
        background-position: bottom 14px left 14px !important; }
  .E1_header .header_center_mobile {
    display: flex;
    flex: 2; }
    .E1_header .header_center_mobile .headerSearchInput {
      padding: 30px;
      color: #ffffff;
      font-family: "Lexand Deca";
      text-align: center;
      margin-top: -8px;
      margin-left: 0px;
      margin-right: 50px;
      width: 100%;
      justify-content: center; }
      .E1_header .header_center_mobile .headerSearchInput .searchInputField {
        background: url("../assets/images/global/header/headerSearchMagnifyingGlass.svg") no-repeat, #151b2b !important;
        box-shadow: 5px 4px 14px #2a2e37;
        border-radius: 64px;
        border: none;
        align-items: flex-start;
        margin: -10px;
        padding: 0px 40px;
        color: #858994;
        font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif !important;
        font-weight: 500;
        font-size: 12pt;
        text-align: left;
        width: 100%;
        height: 43px;
        outline: none;
        background-position: bottom 14px left 14px !important; }
      .E1_header .header_center_mobile .headerSearchInput .searchInputField:focus,
      .E1_header .header_center_mobile .headerSearchInput .searchInputField:hover {
        background: url("../assets/images/global/header/headerSearchMagnifyingGlass.svg") no-repeat, #000000 !important;
        border: none;
        border-radius: 64px;
        outline: none;
        color: #ffffff;
        font-weight: 500;
        background-position: bottom 14px left 14px !important; }
  .E1_header .header_right {
    display: flex;
    justify-content: right;
    padding-bottom: 5px; }
    .E1_header .header_right .badgeCartPopulated {
      border: 0;
      height: '20px';
      width: '20px';
      padding: '0px 0px'; }
    .E1_header .header_right .badgeCartEmpty {
      background: 'transparent';
      border: 0;
      height: '20px';
      width: '20px';
      padding: '0px 0px'; }
    .E1_header .header_right .headerCartIcon {
      margin: 13px 0px 0px 15px;
      padding: 14px 30px 20px 22px;
      background: url("../assets/images/icons/cart.svg") no-repeat;
      background-size: 30px 30px;
      background-position: 0px 20px;
      background-repeat: no-repeat;
      width: 29px;
      max-width: 75px;
      height: 30px; }
    .E1_header .header_right .headerCartIconEmptyCart {
      margin: 13px 0px 0px 15px;
      padding: 14px 30px 22px 20px;
      background: url("../assets/images/global/header/headerCartUnpopulated.svg") no-repeat;
      background-size: 30px 30px;
      background-position: 0px 20px;
      background-repeat: no-repeat;
      width: 31px;
      max-width: 75px;
      height: 30px; }
    .E1_header .header_right .headerCartIconItems {
      color: #ffffff;
      font-size: 13pt;
      margin: 5px 0px 0px 10px;
      font-weight: 900;
      max-width: 50px; }
    .E1_header .header_right .headerCartIconItemsEmptyCart {
      color: transparent;
      font-size: 13pt;
      margin: 5px 0px 0px 10px;
      font-weight: 900;
      max-width: 50px; }
    .E1_header .header_right .profilePhoto {
      padding: 25px 0px 0px 0px;
      max-width: 50px; }
    .E1_header .header_right .imgProfilePhoto {
      z-index: 1; }
    .E1_header .header_right .headerProfilePhotoActive {
      padding-top: 23px;
      background: url("../assets/images/global/header/headerProfilePhotoActive.png") center left no-repeat;
      background-size: 15px 14px;
      background-position: 29px 23px;
      padding: 23px 0px 0px 0px; }
    .E1_header .header_right .headerProfilePhotoInactive {
      padding-top: 23px;
      background: url("../assets/images/global/header/headerProfilePhotoInactive.png") center left no-repeat;
      background-size: 15px 14px;
      background-position: 29px 23px;
      padding: 23px 0px 0px 0px; }
    .E1_header .header_right .headerProfileAccess {
      padding: 30px;
      margin-top: 5px;
      margin-right: 5px;
      color: #ffffff;
      font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-align: right;
      max-width: 100px;
      white-space: nowrap; }
    .E1_header .header_right .headerPrimaryNavigation {
      display: block;
      text-align: right; }
    .E1_header .header_right .headerProfileNameTriggerWrapper {
      margin-right: 10px;
      display: flex;
      flex-direction: row; }
    .E1_header .header_right .headerProfileNameTriggerText {
      text-align: right;
      white-space: pre-line; }
    .E1_header .header_right .headerProfileNameTriggerIcon {
      margin: auto; }
    .E1_header .header_right .headerRightProfileMenuDropdown {
      color: #ffffff !important;
      white-space: nowrap;
      font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif;
      font-weight: 400;
      font-size: 16px;
      height: 83px;
      padding-left: 30px;
      padding-right: 35px;
      padding-top: 2px;
      cursor: pointer;
      -ms-transform: translateY(-50%);
      align-items: center;
      flex-direction: column;
      display: table-cell;
      vertical-align: middle; }
    .E1_header .header_right .headerRightProfileMenuDropdown:hover {
      color: #ffffff;
      -webkit-text-stroke-width: 0.4px;
      -webkit-text-stroke-color: white; }
    .E1_header .header_right .headerRightProfileMenuDropdown:first-child > div {
      font-weight: 400; }
    .E1_header .header_right .headerRightProfileMenu {
      color: #ffffff !important;
      margin-right: 50px !important;
      white-space: nowrap;
      background-color: transparent !important; }
  .E1_header .header_right_tablet {
    display: flex;
    justify-content: right;
    padding-bottom: 5px; }
    @media (min-width: 1024px) and (max-width: 1365.99px) {
      .E1_header .header_right_tablet .headerCartIconContainer {
        width: 75px; } }
    @media (min-width: 768px) and (max-width: 1023.99px) {
      .E1_header .header_right_tablet .headerCartIconContainer {
        width: 0px;
        visibility: hidden; } }
    .E1_header .header_right_tablet .headerCartIcon {
      margin: 13px 0px 0px 15px;
      padding: 14px 30px 20px 22px;
      background: url("../assets/images/icons/cart.svg") no-repeat;
      background-size: 30px 30px;
      background-position: 0px 20px;
      background-repeat: no-repeat;
      width: 29px;
      max-width: 75px;
      height: 30px; }
    .E1_header .header_right_tablet .headerCartIconEmptyCart {
      margin: 13px 0px 0px 15px;
      padding: 14px 30px 22px 20px;
      background: url("../assets/images/global/header/headerCartUnpopulated.svg") no-repeat;
      background-size: 30px 30px;
      background-position: 0px 20px;
      background-repeat: no-repeat;
      width: 31px;
      max-width: 75px;
      height: 30px; }
    .E1_header .header_right_tablet .headerCartIconItems {
      color: #ffffff;
      font-size: 13pt;
      margin: 5px 0px 0px 10px;
      font-weight: 900;
      max-width: 50px; }
    .E1_header .header_right_tablet .headerCartIconItemsEmptyCart {
      color: transparent;
      font-size: 13pt;
      margin: 5px 0px 0px 10px;
      font-weight: 900;
      max-width: 50px; }
    .E1_header .header_right_tablet .profilePhoto {
      padding: 25px 0px 0px 0px;
      max-width: 50px;
      margin-left: 10px;
      margin-right: 30px; }
    .E1_header .header_right_tablet .imgProfilePhoto {
      z-index: 1; }
    .E1_header .header_right_tablet .headerProfilePhotoActive {
      padding-top: 23px;
      background: url("../assets/images/global/header/headerProfilePhotoActive.png") center left no-repeat;
      background-size: 15px 14px;
      background-position: 29px 23px;
      padding: 23px 0px 0px 0px; }
    .E1_header .header_right_tablet .headerProfilePhotoInactive {
      padding-top: 23px;
      background: url("../assets/images/global/header/headerProfilePhotoInactive.png") center left no-repeat;
      background-size: 15px 14px;
      background-position: 29px 23px;
      padding: 23px 0px 0px 0px; }
    .E1_header .header_right_tablet .headerProfileAccess {
      padding: 30px;
      margin-top: 5px;
      margin-right: 5px;
      color: #ffffff;
      font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-align: right;
      max-width: 100px;
      white-space: nowrap; }
    .E1_header .header_right_tablet .headerPrimaryNavigation {
      display: block;
      text-align: right; }
    .E1_header .header_right_tablet .headerProfileNameTriggerWrapper {
      margin-right: 10px;
      display: flex;
      flex-direction: row; }
    .E1_header .header_right_tablet .headerProfileNameTriggerText {
      text-align: right;
      white-space: pre-line; }
    .E1_header .header_right_tablet .headerProfileNameTriggerIcon {
      margin: auto; }
    .E1_header .header_right_tablet .headerRightProfileMenuDropdown {
      color: #ffffff !important;
      white-space: pre-wrap !important;
      font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif;
      font-weight: 400;
      font-size: 16px;
      height: 83px;
      cursor: pointer;
      align-items: center;
      flex-direction: column;
      display: table-cell;
      vertical-align: middle;
      padding-top: 4px; }
      @media (min-width: 1024px) and (max-width: 1365.99px) {
        .E1_header .header_right_tablet .headerRightProfileMenuDropdown {
          margin-top: 35px;
          margin-right: 25px;
          margin-left: 20px; } }
      @media (min-width: 768px) and (max-width: 1023.99px) {
        .E1_header .header_right_tablet .headerRightProfileMenuDropdown {
          margin-top: 35px;
          margin-right: 23px;
          margin-left: -3px; } }
    .E1_header .header_right_tablet .headerRightProfileMenuDropdown:hover {
      color: #ffffff;
      -webkit-text-stroke-width: 0.4px;
      -webkit-text-stroke-color: white; }
    .E1_header .header_right_tablet .headerRightProfileMenuDropdown:first-child > div {
      font-weight: 400; }
    .E1_header .header_right_tablet .headerRightProfileMenu {
      color: #ffffff !important;
      margin-right: 50px !important;
      white-space: nowrap;
      background-color: transparent !important; }
  .E1_header .header_right_mobile {
    display: flex;
    justify-content: right;
    padding-bottom: 5px; }
    .E1_header .header_right_mobile .headerMobileMenuWrapper {
      margin-top: 15px;
      margin-right: 20px;
      margin-left: 20px; }
    .E1_header .header_right_mobile .headerCartIcon {
      margin: 13px 0px 0px 15px;
      padding: 14px 30px 20px 22px;
      background: url("../assets/images/icons/cart.svg") no-repeat;
      background-size: 30px 30px;
      background-position: 0px 20px;
      background-repeat: no-repeat;
      width: 29px;
      max-width: 75px;
      height: 30px; }
    .E1_header .header_right_mobile .headerCartIconEmptyCart {
      margin: 13px 0px 0px 15px;
      padding: 14px 30px 22px 20px;
      background: url("../assets/images/global/header/headerCartUnpopulated.svg") no-repeat;
      background-size: 30px 30px;
      background-position: 0px 20px;
      background-repeat: no-repeat;
      width: 31px;
      max-width: 75px;
      height: 30px; }
    .E1_header .header_right_mobile .headerCartIconItems {
      color: #ffffff;
      font-size: 13pt;
      margin: 5px 0px 0px 10px;
      font-weight: 900;
      max-width: 50px; }
    .E1_header .header_right_mobile .headerCartIconItemsEmptyCart {
      color: transparent;
      font-size: 13pt;
      margin: 5px 0px 0px 10px;
      font-weight: 900;
      max-width: 50px; }
    .E1_header .header_right_mobile .profilePhoto {
      padding: 25px 0px 0px 0px;
      max-width: 50px; }
    .E1_header .header_right_mobile .imgProfilePhoto {
      z-index: 1; }
    .E1_header .header_right_mobile .headerProfilePhotoActive {
      padding-top: 23px;
      background: url("../assets/images/global/header/headerProfilePhotoActive.png") center left no-repeat;
      background-size: 15px 14px;
      background-position: 29px 23px;
      padding: 23px 0px 0px 0px; }
    .E1_header .header_right_mobile .headerProfilePhotoInactive {
      padding-top: 23px;
      background: url("../assets/images/global/header/headerProfilePhotoInactive.png") center left no-repeat;
      background-size: 15px 14px;
      background-position: 29px 23px;
      padding: 23px 0px 0px 0px; }
    .E1_header .header_right_mobile .headerProfileAccess {
      padding: 30px;
      margin-top: 5px;
      margin-right: 5px;
      color: #ffffff;
      font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-align: right;
      max-width: 100px;
      white-space: nowrap; }
    .E1_header .header_right_mobile .headerRightProfileMenuDropdown {
      color: #ffffff !important;
      white-space: nowrap;
      font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif;
      font-weight: 400;
      font-size: 16px;
      height: 15px;
      margin-top: 17px;
      cursor: pointer; }
    .E1_header .header_right_mobile .headerRightProfileMenuDropdown:hover {
      color: #ffffff;
      -webkit-text-stroke-width: 0.4px;
      -webkit-text-stroke-color: white; }
    .E1_header .header_right_mobile .headerRightProfileMenuDropdown:first-child > div {
      font-weight: 400; }
    .E1_header .header_right_mobile .headerRightProfileMenu {
      color: #ffffff !important;
      margin-right: 50px !important;
      white-space: nowrap;
      background-color: transparent !important; }
  .E1_header .forceBlack,
  .E1_header .forceBlack:visited {
    color: #000000; }
  .E1_header .forceWhite {
    color: #ffffff !important; }
  .E1_header .btnMenuLogout {
    background-color: transparent;
    border: none; }
  .E1_header .hero {
    grid-row-start: 2;
    grid-row-end: 3;
    background: #1d2332;
    width: 100%;
    background: #1d2332;
    color: transparent; }

.E1_header_unauth {
  background: transparent;
  color: white;
  display: flex;
  margin: 0px;
  padding: 0px;
  height: 88px; }
  .E1_header_unauth .headerLogo {
    margin-top: 35px;
    margin-left: 25px;
    float: left; }
  .E1_header_unauth .headerLogoBranding {
    float: left;
    margin-right: 10px; }
  .E1_header_unauth .headerLogoMark {
    color: #ffffff;
    font-family: "Lexand Deca", "Google Sans", "Open Sans", "Khula", Helvetica, Verdana, Arial, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2em;
    float: left;
    margin-top: 2px; }
  .E1_header_unauth .headerLogoBrandingClear {
    clear: both; }

DIV.headerContentDropdown {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 6px 20px 0px 0px; }
  DIV.headerContentDropdown A.headerContentDropdownItemWrapper {
    color: #101f47;
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Lexand Deca', 'Google Sans', Khula, 'Open Sans', Lato, Helvetica, Verdana, Arial, sans-serif;
    outline: 1px dashed transparent;
    text-decoration: none;
    cursor: pointer;
    outline: 1px dashed transparent;
    width: 100%;
    border-radius: 8px; }
    DIV.headerContentDropdown A.headerContentDropdownItemWrapper :hover {
      color: #009fd1;
      background-color: #e6f5fa;
      border-radius: 8px; }
    DIV.headerContentDropdown A.headerContentDropdownItemWrapper DIV.headerContentDropdownItemContainer {
      font-weight: 700;
      font-size: 14pt;
      width: 100%;
      padding: 0px 21px 0px 0px;
      margin-bottom: 0px;
      display: flex;
      flex-direction: row; }
      DIV.headerContentDropdown A.headerContentDropdownItemWrapper DIV.headerContentDropdownItemContainer DIV.headerContentDropdownItemLinkText {
        background-color: transparent;
        padding: 12px;
        font-weight: 500;
        font-size: 16px;
        font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif;
        outline: 1px dashed transparent;
        text-decoration: none;
        margin-top: 2px;
        margin-left: 5px;
        width: 100%; }
      DIV.headerContentDropdown A.headerContentDropdownItemWrapper DIV.headerContentDropdownItemContainer DIV.headerContentDropdownItemLinkTextSupplemental > DIV {
        margin-top: 13px;
        margin-right: -13px;
        font-weight: 500;
        font-size: 12px;
        font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif; }
    DIV.headerContentDropdown A.headerContentDropdownItemWrapper DIV.headerContentDropdownItem {
      font-weight: 700;
      font-size: 14pt;
      width: 100%;
      padding: 0px 21px 0px 0px;
      margin-bottom: 0px; }
      DIV.headerContentDropdown A.headerContentDropdownItemWrapper DIV.headerContentDropdownItem DIV.headerContentDropdownItemLinkText {
        background-color: transparent;
        padding: 12px;
        font-weight: 500;
        font-size: 16px;
        font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif;
        outline: 1px dashed transparent;
        text-decoration: none;
        margin-top: 2px;
        margin-left: 5px;
        width: 100%; }
  DIV.headerContentDropdown :last-child {
    padding-bottom: -10px; }
  DIV.headerContentDropdown BUTTON.headerContentDropdownItemLinkButton {
    color: #101f47;
    border: none;
    background-color: transparent;
    padding: 0px 0px 0px 13px;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Lexand Deca', 'Google Sans', Khula, 'Open Sans', Lato, Helvetica, Verdana, Arial, sans-serif;
    outline: 1px dashed transparent;
    width: 112%; }
    DIV.headerContentDropdown BUTTON.headerContentDropdownItemLinkButton DIV.headerContentDropdownItem {
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      padding: 10px 0px 10px 0px;
      margin-bottom: 0px;
      width: 100%; }
      DIV.headerContentDropdown BUTTON.headerContentDropdownItemLinkButton DIV.headerContentDropdownItem DIV.headerContentDropdownItemLink {
        width: 100%;
        padding-left: 5px;
        text-align: left; }
  DIV.headerContentDropdown BUTTON.headerContentDropdownItemLinkButton:hover {
    color: #009fd1;
    background-color: #e6f5fa;
    border-radius: 8px;
    cursor: pointer; }

DIV.headerContentDropdownTrigger {
  color: #ffffff !important;
  width: 125px;
  white-space: nowrap;
  font-family: "Lexand Deca", "Google Sans", Khula, "Open Sans", Lato, Helvetica, Verdana, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-left: 15px;
  margin-top: 17px;
  height: 15px;
  cursor: pointer; }

DIV.headerContentDropdownTrigger:hover {
  color: #ffffff;
  -webkit-text-stroke-width: 0.4px;
  -webkit-text-stroke-color: white; }

/* poput side menu */
DIV.MuiDrawer-paperAnchorRight {
  min-width: 300px;
  background-color: #1d2332 !important;
  color: #ffffff !important; }

UL.MuiList-Root {
  margin-right: 20px; }

DIV.MuiDrawer-modal > DIV.MuiPaper-root {
  border-radius: 15px;
  padding: 6px 0px 0px 0px;
  width: 100%;
  max-width: 180px;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
  z-index: 100; }
  DIV.MuiDrawer-modal > DIV.MuiPaper-root A.MuiListItem-root {
    color: #101f47;
    border: none;
    background-color: transparent;
    font-weight: 500; }

DIV.headerMobileMenuPrimaryLink {
  margin-bottom: 30px; }

DIV.headerMobileMenuSecondaryLink {
  margin-bottom: 20px; }

DIV.headerMobileMenuPrimaryLink:hover,
DIV.headerMobileMenuPrimaryLink:focus,
DIV.headerMobileMenuPrimaryLink:active,
DIV.headerMobileMenuSecondaryLink:hover,
DIV.headerMobileMenuSecondaryLink:focus,
DIV.headerMobileMenuSecondaryLink:active {
  background-color: #009fd1;
  color: #ffffff;
  border-radius: 4px;
  margin-right: 10px;
  width: 89%; }

DIV.headerMobileMenuPrimaryLink > DIV > SPAN {
  font-family: 'Lexand Deca', 'Google Sans', Khula, 'Open Sans', Lato, Helvetica, Verdana, Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 21px;
  color: #bbbdc2; }

DIV.headerMobileMenuSecondaryLink > DIV > SPAN {
  font-family: 'Lexand Deca', 'Google Sans', Khula, 'Open Sans', Lato, Helvetica, Verdana, Arial, sans-serif;
  font-weight: 400;
  line-height: 17px;
  font-size: 16px;
  color: #ffffff; }

.headerMobileMenuDivider {
  width: 90%;
  background-color: #333948;
  height: 1px;
  margin-top: 45px;
  margin-bottom: 30px; }

.headerMenuMobileCloseButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: -20px;
  margin-top: -1px; }

.headerMenuMobileMenuIconOpen {
  position: absolute;
  top: 28px;
  right: 25px;
  cursor: pointer; }

DIV.hostControl-content,
DIV.popup-12 {
  position: absolute;
  top: 70px; }

.dashboardContentWrapper {
  float: left; }
  .dashboardContentWrapper .dashboardBodyContent {
    float: left; }
    .dashboardContentWrapper .dashboardBodyContent .cardWrapper {
      margin: 30px; }
      .dashboardContentWrapper .dashboardBodyContent .cardWrapper .cardActionVerb {
        text-align: center;
        font-weight: 700; }

.dashboardBodyWrapper {
  margin-top: 0px; }

.dashboardSectionWrapper {
  border: none; }

.dashboardSection {
  border: none;
  padding-top: 15px; }

.dashboardSectionHeader,
.dashboardSectionHeader:visited {
  color: #101f47;
  font-weight: bold;
  font-size: 24px;
  flex: none;
  order: 0;
  text-decoration: none;
  flex-grow: 0; }

.dashboardSectionHeaderFooter {
  background: #00cbe9;
  border-radius: 24px;
  height: 4px;
  width: 49px;
  margin-top: 7px;
  line-height: 4px; }

.mainContentWrapper {
  flex: auto;
  margin-top: 40px;
  vertical-align: top;
  display: grid;
  flex: 1 0 auto; }
  @media (min-width: 1920px) {
    .mainContentWrapper {
      grid-template-columns: 200px auto 200px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    .mainContentWrapper {
      grid-template-columns: 200px auto 200px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    .mainContentWrapper {
      grid-template-columns: 80px auto 80px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    .mainContentWrapper {
      grid-template-columns: 40px auto 40px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    .mainContentWrapper {
      grid-template-columns: 15px auto 15px; } }
  @media (max-width: 359.99px) {
    .mainContentWrapper {
      grid-template-columns: 15px auto 15px; } }

DIV.searchHero ~ DIV.mainContentWrapperCourse {
  margin-top: 40px;
  vertical-align: top;
  display: grid;
  background-color: transparent;
  flex: 1 0 auto; }
  @media (min-width: 1920px) {
    DIV.searchHero ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 425px auto 200px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.searchHero ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 425px auto 200px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV.searchHero ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 40px auto 40px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV.searchHero ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 40px auto 40px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.searchHero ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 15px auto 15px; } }
  @media (max-width: 359.99px) {
    DIV.searchHero ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 15px auto 15px; } }

DIV.heroCourseContentWrapper ~ DIV.mainContentWrapperCourse {
  margin-top: 40px;
  vertical-align: top;
  display: grid;
  background-color: transparent;
  flex: 1 0 auto; }
  @media (min-width: 1920px) {
    DIV.heroCourseContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 425px auto 200px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.heroCourseContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 425px auto 200px; } }
  @media (min-width: 768px) and (max-width: 1365.99px) {
    DIV.heroCourseContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 325px auto 50px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.heroCourseContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 15px auto 15px; } }
  @media (max-width: 359.99px) {
    DIV.heroCourseContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 15px auto 15px; } }

DIV.heroCatalogContentWrapper ~ DIV.mainContentWrapperCourse {
  margin-top: 40px;
  vertical-align: top;
  display: grid;
  background-color: transparent;
  flex: 1 0 auto; }
  @media (min-width: 1920px) {
    DIV.heroCatalogContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 425px auto 200px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.heroCatalogContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 425px auto 200px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV.heroCatalogContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 50px auto 50px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV.heroCatalogContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 50px auto 50px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.heroCatalogContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 15px auto 15px; } }
  @media (max-width: 359.99px) {
    DIV.heroCatalogContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 15px auto 15px; } }

DIV.heroMyCoursesContentWrapper ~ DIV.mainContentWrapperCourse {
  margin-top: 40px;
  vertical-align: top;
  display: grid;
  background-color: transparent;
  flex: 1 0 auto; }
  @media (min-width: 1920px) {
    DIV.heroMyCoursesContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 425px auto 200px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    DIV.heroMyCoursesContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 425px auto 200px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    DIV.heroMyCoursesContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 50px auto 50px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    DIV.heroMyCoursesContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 50px auto 50px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    DIV.heroMyCoursesContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 15px auto 15px; } }
  @media (max-width: 359.99px) {
    DIV.heroMyCoursesContentWrapper ~ DIV.mainContentWrapperCourse {
      grid-template-columns: 15px auto 15px; } }

.mainContentWrapperCart {
  margin-top: 40px;
  vertical-align: top;
  display: grid;
  min-height: 800px;
  background-color: transparent;
  height: 100%; }
  @media (min-width: 1920px) {
    .mainContentWrapperCart {
      grid-template-columns: 200px auto 0px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    .mainContentWrapperCart {
      grid-template-columns: 200px auto 0px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    .mainContentWrapperCart {
      grid-template-columns: 80px auto 0px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    .mainContentWrapperCart {
      grid-template-columns: 40px auto 0px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    .mainContentWrapperCart {
      grid-template-columns: 15px auto 0px; } }
  @media (max-width: 359.99px) {
    .mainContentWrapperCart {
      grid-template-columns: 15px auto 0px; } }

.mainContentWrapperProfile {
  margin-top: 40px;
  vertical-align: top;
  display: grid;
  background-color: transparent; }
  @media (min-width: 1920px) {
    .mainContentWrapperProfile {
      grid-template-columns: 391px 0px auto auto 200px; } }
  @media (min-width: 1366px) and (max-width: 1919.99px) {
    .mainContentWrapperProfile {
      grid-template-columns: 391px 0px auto auto 200px; } }
  @media (min-width: 1024px) and (max-width: 1365.99px) {
    .mainContentWrapperProfile {
      grid-template-columns: 80px 0px auto auto 80px; } }
  @media (min-width: 768px) and (max-width: 1023.99px) {
    .mainContentWrapperProfile {
      grid-template-columns: 40px 0px auto auto 40px; } }
  @media (min-width: 360px) and (max-width: 767.99px) {
    .mainContentWrapperProfile {
      grid-template-columns: 15px 0px auto auto 15px; } }
  @media (max-width: 359.99px) {
    .mainContentWrapperProfile {
      grid-template-columns: 15px 0px auto auto 15px; } }

.mainContentContainer {
  background-color: transparent; }

.mainContentContainerFilters {
  margin-left: 194px;
  margin-top: 20px;
  flex: 1 0 auto; }

.mainBodyContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px; }

.filterHeaderText {
  font-weight: 700;
  margin-left: 0px;
  font-size: 18px;
  line-height: 24px;
  color: #1d2332; }

.leftSidebar {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0px 8px 0px 0px;
  min-width: 180px;
  max-width: 200px;
  min-height: 100%;
  max-height: 100%;
  margin-right: 20px; }

.leftSidebarCourse {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0px 8px 0px 0px;
  min-width: 400px;
  max-width: 400px;
  min-height: 100%;
  max-height: 100%;
  margin-right: 20px; }

.rightSidebar {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px 0px 0px 0px;
  min-width: 180px;
  max-width: 180px;
  min-height: 100%;
  max-height: 100%;
  margin-left: 20px; }

.courseCardImage {
  width: 100%;
  height: 100%;
  min-height: 230px;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
  object-fit: cover;
  -webkit-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out; }

.cardImageContainer {
  width: 100%;
  max-width: 232px;
  min-width: 232px;
  height: 100%;
  max-height: 230px;
  min-height: 230px;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
  overflow: hidden; }

.courseCard:hover IMG {
  transform-style: preserve-3d;
  transform: perspective(400px) translatez(35px);
  transition: transform 800ms ease-out;
  overflow: hidden; }

.cardWrapper {
  border: none;
  min-width: 232px;
  max-width: 232px;
  min-height: 334px;
  max-height: 334px;
  margin-right: 30px;
  margin-bottom: 40px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px; }

.cardAction {
  display: flex;
  flex-direction: column;
  height: 113px;
  min-height: 113px;
  max-height: 113px;
  width: 232px;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px; }

.cardTitle {
  flex-grow: 1;
  margin: auto 10px auto 18px;
  color: #101f47;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  justify-content: left;
  text-align: left;
  cursor: pointer;
  border: none;
  background-color: transparent;
  align-items: center;
  display: flex;
  max-height: fit-content; }
  .cardTitle DIV.cardTitleHolder {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 10px; }

.cardActionWrapper {
  text-align: center;
  background-color: transparent;
  border: none; }

.cardActionButtonWrapper {
  margin-top: auto;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  border-top: 1px solid #edeff3; }

.cardProgressIndicator {
  margin-right: 0px;
  margin-top: 0px;
  height: 5px;
  background-color: #ffffff; }

.catalogPageLoadingIndicator,
.myCoursesPageLoadingIndicator {
  width: 100%;
  text-align: center; }

.noElement {
  visibility: hidden;
  max-height: 0px;
  max-width: 0px; }

.btnCloseCourseModal {
  background-color: silver;
  padding: 6px;
  color: black;
  border: 1px solid black; }

.fullSizeMessaging {
  height: 100%;
  font-size: 12pt; }

.lmsItemGroupWrapper {
  border: none;
  border-radius: 8px; }

.lmsItemGroupName {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #8c909b;
  margin-bottom: 15px; }

.chapterWrapper {
  border: none; }

.chapterName {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #8c909b;
  margin-bottom: 15px; }

.lmsItemWrapper {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: auto 191px;
  grid-template-rows: auto 40px;
  margin-bottom: 20px;
  border-radius: 8px 8px 8px 8px; }

.lmsItemInfo {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding: 15px; }

.downArrow {
  color: #ffffff;
  transform: rotate(45deg);
  border: solid currentColor;
  border-width: 0 0.2em 0.2em 0;
  display: inline-block;
  padding: 0.2em;
  margin-left: 6px;
  margin-bottom: 3px; }

.loadMoreWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-left: -14px;
  margin-top: 5px; }

.loadingIndicator {
  position: absolute;
  top: calc((100vh - 100px) / 2);
  left: calc((100vw - 100px) / 2); }

.inlineIconAndText {
  display: flex;
  flex-direction: row; }

.inlineHeadline {
  display: inline-block;
  margin-bottom: 15px;
  font-size: 26pt;
  font-weight: 700; }

.smallParagraphBreak {
  margin-bottom: 10px; }

.largeParagraphBreak {
  margin-bottom: 30px; }

A.textLink {
  color: #1d2332;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
  cursor: pointer; }

A.testLink:visited,
A.textLink:hover {
  color: #009fd1; }

DIV.MuiTooltip-popper > DIV {
  font-family: 'Lexand Deca', sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: block;
  background: rgba(52, 61, 85, 0.6);
  backdrop-filter: blur(12px);
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400; }

.E1_footer {
  width: 100vw !important;
  color: #858994;
  min-height: 120px;
  width: 100%;
  background-color: #ffffff; }
  @media (min-width: 768px) {
    .E1_footer {
      display: grid;
      grid-template-rows: 100%;
      grid-template-columns: 50% 50%;
      grid-auto-flow: row;
      grid-column-gap: 10px;
      grid-row-gap: 10px; } }
  @media (max-width: 767.99px) {
    .E1_footer {
      display: flex;
      flex-direction: column; } }
  .E1_footer .signature {
    background-color: #ffffff;
    color: #858994;
    font-family: "Nunito Sans", "Lexand Deca", "Khula", "Google Sans", "Open Sans", Helvetica, Verdana, Arial, sans-serif;
    white-space: nowrap; }
    @media (min-width: 1920px) {
      .E1_footer .signature {
        margin-top: 50px;
        margin-left: 208px;
        text-align: left; } }
    @media (min-width: 1366px) and (max-width: 1919.99px) {
      .E1_footer .signature {
        margin-top: 50px;
        margin-left: 208px;
        text-align: left; } }
    @media (min-width: 1024px) and (max-width: 1365.99px) {
      .E1_footer .signature {
        margin-top: 50px;
        margin-left: 80px;
        text-align: left; } }
    @media (min-width: 768px) and (max-width: 1023.99px) {
      .E1_footer .signature {
        margin-top: 50px;
        margin-left: 40px;
        text-align: left; } }
    @media (min-width: 360px) and (max-width: 767.99px) {
      .E1_footer .signature {
        margin-top: 30px;
        margin-left: 0px;
        text-align: center; } }
    @media (max-width: 359.99px) {
      .E1_footer .signature {
        margin-top: 30px;
        margin-left: 0px;
        text-align: center; } }
    .E1_footer .signature BUTTON {
      color: #858994;
      width: 5px;
      padding: 0px !important;
      min-width: 3px !important; }
  .E1_footer .social {
    background-color: #ffffff;
    display: flex;
    flex-direction: row-reverse; }
    @media (min-width: 1920px) {
      .E1_footer .social {
        margin-top: 39px;
        margin-right: 175px;
        text-align: right; } }
    @media (min-width: 1366px) and (max-width: 1919.99px) {
      .E1_footer .social {
        margin-top: 39px;
        margin-right: 175px;
        text-align: right; } }
    @media (min-width: 1024px) and (max-width: 1365.99px) {
      .E1_footer .social {
        margin-top: 39px;
        margin-right: 80px;
        text-align: right; } }
    @media (min-width: 768px) and (max-width: 1023.99px) {
      .E1_footer .social {
        margin-top: 39px;
        margin-right: 40px;
        text-align: right; } }
    @media (min-width: 360px) and (max-width: 767.99px) {
      .E1_footer .social {
        margin-top: 24px;
        margin-right: 0px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        justify-content: center; } }
    @media (max-width: 359.99px) {
      .E1_footer .social {
        margin-top: 24px;
        margin-right: 0px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        justify-content: center; } }
    .E1_footer .social .social_item {
      padding-right: 15px; }
      @media (max-width: 767.99px) {
        .E1_footer .social .social_item {
          margin-bottom: 20px; } }
      .E1_footer .social .social_item A > IMG.footerAuthSocialMediaIcon {
        opacity: 0.5; }

.E1_footer_gap {
  min-height: 50px; }

.E1_footer_unauth {
  position: absolute;
  bottom: 0;
  color: #858994;
  min-height: 0px;
  background-color: transparent;
  margin-top: 50px;
  width: 100%;
  float: left; }
  .E1_footer_unauth .signature {
    float: left;
    background-color: transparent;
    text-align: left;
    margin-left: 35px;
    margin-top: 10px;
    margin-bottom: 50px;
    color: #ffffff;
    font-family: "Nunito Sans", "Lexand Deca", "Khula", "Google Sans", "Open Sans", Helvetica, Verdana, Arial, sans-serif;
    white-space: nowrap; }
    @media (max-width: 600px) {
      .E1_footer_unauth .signature {
        visibility: hidden; } }
    .E1_footer_unauth .signature BUTTON {
      color: #ffffff;
      width: 5px;
      padding: 0px !important;
      min-width: 3px !important; }
  .E1_footer_unauth .social {
    background-color: transparent; }
    @media (min-width: 1101px) {
      .E1_footer_unauth .social {
        text-align: right;
        margin-right: 550px;
        float: right; } }
    @media (min-width: 601px) and (max-width: 1100px) {
      .E1_footer_unauth .social {
        text-align: right;
        margin-right: 50px;
        float: right; } }
    @media (max-width: 600px) {
      .E1_footer_unauth .social {
        visibility: hidden; } }
  .E1_footer_unauth .social_item {
    padding-right: 15px;
    float: right; }

/*
* Sass page layouts
*/
/*
* Sass page layout
*/
.Container {
  background-color: #ffffff; }

html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  min-height: 100%;
  background-color: #f2f4f9; }

A,
A:visited {
  color: #009fd1; }

A:hover {
  color: #00cbe9; }

#root {
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  background-color: #f2f4f9; }

div.bodyContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  left: 0;
  min-width: 320px;
  position: absolute;
  right: 0;
  bottom: 10px;
  margin: 0px;
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden; }
  div.bodyContainer content {
    flex: 88px auto 120px; }
  div.bodyContainer wrapper {
    padding: 20px;
    grid-row-start: 3;
    grid-row-end: 4; }

@media print {
  .E1_header,
  .GenericMediumHeroContentWrapper,
  .E1_footer,
  .E1_footer_gap,
  .headerSearchInput,
  .rightSidebar,
  .searchInputField {
    display: none; }
  @page {
    margin: 0.5cm;
    size: portrait; }
  div {
    overflow: hidden !important; }
  html,
  body {
    height: 99% !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden; }
  .bodyContainer {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: visible !important; }
  .bodyContainer::-webkit-scrollbar {
    width: 0 !important; }
  * {
    page-break-after: avoid;
    page-break-before: avoid; }
  div#root {
    border: none; }
  div#bodyContainer {
    border: none; }
  .printWrapper {
    border: none; }
  .transcriptsDataTable {
    border: none; }
  .transcriptsDataTableContent {
    border: none; }
  .transcriptContainer {
    border: none; } }

/*
* Sass partial page layouts
*/
/*
* Sass themes
*/
